import * as React from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddEvent from "../../components/AddEvent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { localEncrypted } from "../../apis/utils";

export default function EventList(props) {
  const { organizationName, eventList, createEventFunction, setTabActive, t } =
    props;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const goEvent = (id) => {
    localEncrypted(id, "selectEvent");
    return navigate("/overviews");
  };

  const [searchEvent, setsearchEvent] = React.useState("");
  const [filterData, setfilterData] = React.useState([]);

  React.useEffect(() => {
    if (eventList.length > 0) {
      const filteredList = eventList?.filter((event) => {
        return event.name.toUpperCase().includes(searchEvent.toUpperCase()); // Utiliza "includes" en lugar de "include"
      });

      setfilterData(filteredList);
    } else {
      setfilterData(eventList);
    }
  }, [eventList, searchEvent]);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        justifyContent={"space-between"}
        display={"flex"}
        alignItems={"center"}
      >
        <Grid display={"flex"} justifyContent="flex-start" alignItems="center">
          <IconButton onClick={() => setTabActive(0)}>
            <ArrowBackIcon />
          </IconButton>

          <h2>{organizationName}</h2>
        </Grid>

        <TextField
          size="small"
          placeholder={t("page.myevents.search")}
          onChange={(e) => {
            setsearchEvent(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <Button
          variant="contained"
          fullWidth
          size="large"
          onClick={() => setOpen(true)}
        >
          {t("page.myevents.createEvent")}
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        style={{
          backgroundColor: "white",
          marginTop: "20px",
          borderRadius: "30px",
        }}
      >
        {eventList.length > 0 ? (
          filterData.map((item, indice) => (
            <Grid container key={indice} xs={12} spacing={2} p>
              <Grid item xs={12} md={4} lg={4}>
                <Box
                  justifyContent={"end"}
                  alignItems={"center"}
                  display={"flex"}
                  maxWidth={"100%"}
                >
                  <img
                    src={item.logo}
                    alt="banner"
                    style={{
                      borderRadius: "15px",
                      maxWidth: "352px",
                      height: "auto",
                      width: "100%",
                      margin: "auto",
                    }}
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                md={8}
                lg={8}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{
                  textAlign: { xs: "center", md: "left" },
                  display: { xs: "initial", md: "flex" },
                }}
              >
                <Typography my={2} fontWeight={"bold"}>
                  {item.name}
                </Typography>

                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => goEvent(item.id)}
                  style={{
                    borderRadius: "30px",
                    width: "20%",
                    minWidth: "200px",
                  }}
                >
                  {t("page.myevents.viewEvent")}
                </Button>
              </Grid>

              <Grid item xs={12} style={{ margin: "20px" }}>
                <Divider />
              </Grid>
            </Grid>
          ))
        ) : (
          <></>
        )}
      </Grid>
      <Drawer anchor={"right"} open={open} onClose={() => setOpen(false)}>
        <AddEvent save={createEventFunction} setOpen={setOpen} />
      </Drawer>
    </Grid>
  );
}
