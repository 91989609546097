import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InfoIcon from "@mui/icons-material/Info";
import {
  Alert,
  IconButton,
  Typography,
  Dialog,
  CircularProgress,
  Box,
  Button,
} from "@mui/material";

export default function ModalCustom(props) {
  const { modalConfig, setOpen } = props;
  const { success, error, loading, open, msg, title } = modalConfig
    ? modalConfig
    : props;

  return (
    <Dialog open={open}>
      {loading ? (
        <div
          style={{
            textAlign: "center",
            minWidth: "200px",
            padding: "20px 0",
            background: "transparent",
          }}
        >
          <CircularProgress size={"50px"} color="primary" />
        </div>
      ) : success ? (
        <Alert
          size="lg"
          style={{ minWidth: "300px", padding: "20px" }}
          color="success"
          variant="filled"
          icon={<CheckCircleOutlineIcon sx={{ fontSize: 40 }} />}
          action={
            <IconButton
              style={{
                color: "white",
              }}
              onClick={() => setOpen(1)}
            >
              <CancelIcon />
            </IconButton>
          }
        >
          <div>
            <Typography level="title-lg">{title ? title : "Éxito"}</Typography>
            <Typography level="body-sm">{msg ? msg : ""}</Typography>
          </div>
        </Alert>
      ) : error ? (
        <Alert
          size="lg"
          style={{ minWidth: "300px", padding: "20px" }}
          color="error"
          variant="filled"
          icon={<HighlightOffIcon sx={{ fontSize: 40 }} />}
          action={
            <IconButton
              color="default"
              style={{
                color: "white",
              }}
              onClick={() => setOpen(2)}
            >
              <CancelIcon />
            </IconButton>
          }
        >
          <div style={{ width: "100%" }}>
            <Typography level="title-lg">{title ? title : "Error"}</Typography>
            <Typography level="body-sm">{msg ? msg : ""}</Typography>
          </div>
        </Alert>
      ) : (
        <Alert
          size="lg"
          style={{ minWidth: "300px", padding: "20px" }}
          color="info"
          variant="filled"
          icon={<InfoIcon sx={{ fontSize: 40 }} />}
        >
          <div>
            <Typography level="title-lg">
              {title ? title : "Información"}
            </Typography>
            <Typography level="body-sm">
              {msg ? msg : "Algo a ocurrido intente mas tarde."}
            </Typography>
            <IconButton
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                color: "white",
              }}
              onClick={() => setOpen(3)}
            >
              <CancelIcon />
            </IconButton>
          </div>
          <Box
            sx={{
              mt: 2,
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => setOpen(3)}
              variant="contained"
              color="primary"
              size="sm"
            >
              OK
            </Button>
          </Box>
        </Alert>
      )}
    </Dialog>
  );
}
