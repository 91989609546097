import React from "react";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

import { modalMsgFunction } from "../../assets/library/GlobalFunction";
import ModalCustom from "../../components/ModalCustom";
import Layout from "../../components/Layout";
import TableUsers from "../../components/TableUsers";
import {
  apideleteUser,
  deleteUser,
  getUser,
  getUserList,
  setUser,
} from "../../apis/Services/UsersService";
import { finddataMeta } from "../../apis/utils";

export default function Index() {
  const [t] = useTranslation("global");
  const [modalConfig, setModalConfig] = React.useState(modalMsgFunction(false));
  const [searchEvent, setsearchEvent] = React.useState("");
  const [filterData, setfilterData] = React.useState([]);
  const [name, setName] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [userIdEdit, setUserIdEdit] = React.useState("");
  const [error, setError] = React.useState({
    name: false,
    email: false,
  });
  const [allData, setAllData] = React.useState([]);
  const navigate = useNavigate();
  const [superAdmin, setsuperAdmin] = React.useState(false)
  const [opedelete, setopedelete] = React.useState(false)
  const [userDelete, setuserDelete] = React.useState('')

  const closeModal = (type) => {
    setModalConfig({ ...modalConfig, open: false });
  };
  React.useEffect(() => {
    getAllData();
    getMeta()
  }, []);

  async function getMeta() {
    const permitionSuperAdmin = await finddataMeta();
    const json = JSON.parse(permitionSuperAdmin)

    setsuperAdmin(json.superadmin)
  }


  const getAllData = () => {
    getUserList()
      .then((result) => {
        setAllData(result);

      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    if (allData.length > 0) {
      const filteredList = allData?.filter((organization) => {
        return organization.name
          .toUpperCase()
          .includes(searchEvent.toUpperCase()); // Utiliza "includes" en lugar de "include"
      });
      setfilterData(filteredList);
    } else {
      setfilterData(allData);
    }
  }, [allData, searchEvent]);

  const deleteUserFunctin = (id) => {
    setopedelete(true)
    setuserDelete(id)

  }
  const getDataEditUser = (id) => {
    setModalConfig(modalMsgFunction(true, 0));
    setUserIdEdit(id);

    getUser(id)
      .then((result) => {

        setName(result.name);
        setEmail(result.email);
        setPhone(result.phone);
        closeModal();
        setOpen(true);
      })
      .catch((err) => {

        closeModal();
      });
  };

  async function deleteApi() {

    const deleteUserA = await apideleteUser(userDelete);
    setopedelete(false)
    getAllData();

  }

  const editUser = () => {
    let json = {
      name: name,
      email: email,
      phone: phone,
    };

    setUser(userIdEdit, json)
      .then((result) => {

        closeModal();

        setOpen(false);
        getAllData();

      })
      .catch((err) => {
        console.log(err);
        closeModal();
        setOpen(false);
      });
  };

  return (
    <>
      <ModalCustom modalConfig={modalConfig} setOpen={closeModal} />

      <Layout isHome={true} isAdmin={superAdmin}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                justifyContent={"space-between"}
                display={"flex"}
                alignItems={"center"}
              >
                <h1> {t("page.users.title")}</h1>
                <TextField
                  size="small"
                  placeholder={t("page.users.search")}
                  name="searchOrganization"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setsearchEvent(e.target.value);
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid my={2}>
          <Divider />
        </Grid>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <TableUsers
                  rows={filterData}
                  viewFunction={null}
                  editFunction={getDataEditUser}
                  deleteFunction={deleteUserFunctin}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Layout>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle textAlign={"center"}>
          {t("page.myevents.TitleeditUser")}
        </DialogTitle>
        <DialogContent>
          <Typography color={"#000"}>
            {t("page.myevents.name")}
          </Typography>
          <TextField
            size="small"
            placeholder={t("page.myevents.organizationName")}
            fullWidth
            name="Organizor"
            error={error.name}
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Typography color={"#000"} mt={2}>
            {t("page.myevents.phone")}
          </Typography>
          <TextField
            size="small"
            placeholder={t("page.myevents.Phone")}
            fullWidth
            name="phone"
            error={error.phone}
            variant="outlined"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Typography color={"#000"} mt={2}>
            {t("page.myevents.email")}
          </Typography>
          <TextField
            size="small"
            placeholder="email@example.com"
            fullWidth
            name="email"
            error={error.email}
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => editUser()} fullWidth>
            {t("page.myevents.save")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={opedelete}
        onClose={() => {
          setopedelete(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("page.users.delete")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ color: "black" }}>
            {t("page.users.confirmDelete")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setopedelete(false)
          }}>NO</Button>
          <Button onClick={() => {
            deleteApi()
          }} autoFocus>
            {t("page.users.delete")}
          </Button>
        </DialogActions>
      </Dialog>


    </>
  );
}
