import React from "react";
import "./assets/css/globals.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { I18nextProvider } from "react-i18next";
import global_example from "./assets/translations/example/global.json";
import global_en from "./assets/translations/en/global.json";
import global_es from "./assets/translations/es/global.json";
import global_pt from "./assets/translations/pt/global.json";
import global_it from "./assets/translations/it/global.json";
import { apiGetSession } from "./apis";
import { nextService } from "./apis/Services/Authentication";

import i18next from "i18next";
import ErrorPage from "./pages/error/error-page";
import Login from "./pages/login";
import Overviews from "./pages/overviews/index";
import Home from "./pages/home";
import Analytics from "./pages/analytics/index";
import Events from "./pages/events/index";
import Agenda from "./pages/agenda/index";
import Sponsors from "./pages/sponsors/index";
import Visited from "./pages/visited/index";
import Recover from "./pages/login/recover";
import Preview from "./pages/preview/index";
import MyEvents from "./pages/myEvents";
import User from "./pages/users/Index";
import Tag from "./pages/tag";
import Calendar from "./pages/calendar";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    es: {
      global: global_es,
    },
    pt: {
      global: global_pt,
    },
    it: {
      global: global_it,
    },
    example: {
      global: global_example,
    },
  },
});

const theme = createTheme({
  palette: {
    primary: { main: "#2f557f", contrastText: "#fff" },
    secondary: { main: "#6f6f6f", contrastText: "#000" },
    text: { secondary: "#f0f0f0" },
  },
});

function App() {
  React.useEffect(() => {
    apiGetSession().then((result) => {
      localStorage.setItem("session", result.init);
      nextService({ data: result.init }).then((r) => {
        localStorage.setItem("out", r.access_token);
      });
    });
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" errorElement={<ErrorPage />}>
        <Route index element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="home" element={<Home />} />
        <Route path="Users" element={<User />} />
        <Route path="myEvents" element={<MyEvents />} />
        <Route path="recover" element={<Recover />} />
        <Route path="overviews" element={<Overviews />} />
        <Route path="analytics" element={<Analytics />} />
        <Route path="events" element={<Events />} />
        <Route path="agenda" element={<Agenda />} />
        <Route path="sponsors" element={<Sponsors />} />
        <Route path="visited" element={<Visited />} />
        <Route path="preview" element={<Preview />} />
        <Route path="calendar" element={<Calendar />} />
        <Route path="tag" element={<Tag />} />
      </Route>
    )
  );

  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18next}>
        <RouterProvider router={router} />
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
