import * as React from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

export default function OrganizerEvents(props) {
  const {
    organizationList,
    changeModalOrganizer,
    t,
    selectEvent,
    deleteOrganizationFunction,
    openUserOrg,
    userOrg,
    showuserOrg,
    setshowuserOrg,
    delUserOrg,
    setvinculeUserOrg,
  } = props;

  const [searchEvent, setsearchEvent] = React.useState("");
  const [filterData, setfilterData] = React.useState([]);
  const [idShowuserOrg, setIdShowuserOrg] = React.useState(0);

  React.useEffect(() => {
    if (organizationList) {
      const filteredList = organizationList?.filter((organization) => {
        return organization.name
          .toUpperCase()
          .includes(searchEvent.toUpperCase()); // Utiliza "includes" en lugar de "include"
      });
      setfilterData(filteredList);
    } else {
      setfilterData(organizationList);
    }
  }, [organizationList, searchEvent]);

  const selectEmailAccount = (id) => {
    setshowuserOrg(false);
    setIdShowuserOrg(id);
    openUserOrg(id);
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        justifyContent={"space-between"}
        display={"flex"}
        alignItems={"center"}
      >
        <h1> {t("page.myevents.title")}</h1>
        <TextField
          size="small"
          placeholder={t("page.myevents.search")}
          name="searchOrganization"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setsearchEvent(e.target.value);
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid
        item
        xs={12}
        md={12}
        style={{
          alignContent: "flex-end",
          alignItems: "flex-end",
          textAlign: "right",
        }}
      >
        <Button
          variant="contained"
          fullWidth
          size="large"
          onClick={() => changeModalOrganizer(false, null)}
          style={{ width: "100%" }}
        >
          {t("page.myevents.createOrganization")}
        </Button>
      </Grid>

      <Grid item xs={12} md={12}>
        {filterData ? (
          filterData.map((item, indice) => (
            <>
              <Grid
                key={indice}
                // display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                mb={2}
                container
                style={{
                  width: "100%",
                  border: "1px solid grey", // Aplica un borde alrededor del Grid
                  padding: "10px",
                  borderRadius: "20px",
                  backgroundColor: "white",
                }}
              >
                <Grid xs={2} lg={2} md={2}>
                  {item.image ? (
                    <img
                      style={{
                        borderRadius: "10px",
                        maxWidth: "100px",
                        //maxHeight: "200px",
                        width: "100%",
                      }}
                      src={item.image}
                      id="imgPreview"
                      alt="image"
                    />
                  ) : (
                    <AccountCircleIcon style={{ fontSize: "50px" }} />
                  )}
                </Grid>
                <Grid xs={7} lg={7} md={7}>
                  <p>{item.name}</p>
                </Grid>

                <Grid
                  xs={3}
                  lg={3}
                  md={3}
                  style={{ justifyContent: "flex-end" }}
                >
                  <IconButton onClick={() => selectEvent(item.id)}>
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    color="warning"
                    onClick={() => changeModalOrganizer(true, item.id)}
                  >
                    <ModeEditIcon />
                  </IconButton>

                  <IconButton
                    color="danger"
                    onClick={() => {
                      selectEmailAccount(item.id);
                    }}
                  >
                    <ManageAccountsIcon />
                  </IconButton>

                  <IconButton
                    color="error"
                    onClick={() => {
                      if (window.confirm(t("page.myevents.delete"))) {
                        deleteOrganizationFunction(item.id);
                      }
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>

              {idShowuserOrg == item.id && showuserOrg && (
                <Grid className="widthMiniTable">
                  <Grid style={{ padding: 10 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setvinculeUserOrg(true);
                      }}
                      style={{ margin: 10 }}
                    >
                      Vincular
                    </Button>
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={() => {
                        setshowuserOrg(false);
                      }}
                      style={{ margin: 10 }}
                    >
                      Ocultar
                    </Button>
                  </Grid>

                  <Grid style={{ padding: 10 }}>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              {" "}
                              {t("component.tableUsers.name")}
                            </TableCell>
                            <TableCell align="right">
                              {" "}
                              {t("component.tableUsers.email")}
                            </TableCell>
                            <TableCell align="right">
                              {" "}
                              {t("component.tableUsers.option")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {userOrg.map((row) => (
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row.name}
                              </TableCell>

                              <TableCell align="right">{row.email}</TableCell>

                              <TableCell align="center">
                                <IconButton
                                  color="warning"
                                  onClick={() => {
                                    delUserOrg(row.id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              )}
            </>
          ))
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
}
