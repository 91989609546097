import * as React from "react";
import {
  VisibilityOff,
  Visibility,
  Google,
  Facebook,
  Apple,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import logo from "../../assets/img/advlogo.png";
import { validateEmail } from "../../assets/library/GlobalFunction";
import ModalCustom from "../../components/ModalCustom";
import { signin } from "../../apis/Services/Authentication";

export default function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [user, setUser] = React.useState("");
  const [pass, setPass] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [successApi, setSuccessApi] = React.useState(false);
  const [error, setError] = React.useState({ user: false, pass: false });
  const [errorMsg, setErrorMsg] = React.useState({ title: "", msg: "" });
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const login = () => {
    let _error = { user: false, pass: false };
    if (!validateEmail(user)) {
      _error.user = true;
    }
    if (!pass) {
      _error.pass = true;
    }
    setError(_error);
    if (_error.user || _error.pass) {
      return;
    }
    let json = { username: user, password: pass };
    setOpen(true);
    setLoading(true);
    signin(json)
      .then((result) => {
        if (result.access_token) {
          setLoading(false);
          setSuccessApi(true);

          localStorage.setItem("meta", result.meta);
          localStorage.setItem("aet", result.access_token);
          navigate("/myevents");
          return;
        } else {
          if (result.statusCode === 404) {
            setErrorMsg({ title: "Error", msg: "User or Pass incorrect" });
          }
          setLoading(false);
          setSuccessApi(false);
        }
      })
      .catch(() => {
        setLoading(false);
        setSuccessApi(false);
      });
    //return navigate("/home");
  };

  const closeModal = (isOpen) => {
    setOpen(!open);
    if (successApi) {
      navigate("/myevents");
    }
  };

  return (
    <>
      <ModalCustom
        open={open}
        setOpen={closeModal}
        loading={loading}
        success={successApi}
        error={!successApi}
        msg={errorMsg.msg}
        title={errorMsg.title}
      />
      <Grid
        container
        minHeight={"102vh"}
        spacing={2}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item xs={12} md={6}>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <img
              src={logo}
              alt="logo"
              width="160"
              height="160"
              className="imgIcon"
            />
          </Grid>
          <Grid item xs={10} marginBottom={3}></Grid>
          <Grid item xs={10} md={8} margin={"auto"}>
            <form className="">
              <label className="text-left">User</label>
              <TextField
                name="email"
                variant="outlined"
                placeholder="Email"
                error={error.user}
                fullWidth
                onChange={(e) => setUser(e.target.value)}
              />
              <Grid marginTop={"30px"}>
                <label className="text-left">Password</label>
              </Grid>
              <FormControl variant="outlined" fullWidth>
                {/* <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel> */}
                <OutlinedInput
                  onChange={(e) => setPass(e.target.value)}
                  placeholder="Password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  error={error.pass}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="mostrar/ocultar contraseña"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="start"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                //label="Password"
                />
              </FormControl>
              <Grid
                textAlign={"right"}
                marginBottom={"19px"}
                marginTop={"16px"}
              >
                <Link
                  to="/recover"
                  color="primary"
                  style={{ textDecoration: "none" }}
                >
                  Recovery pass
                </Link>
              </Grid>
              <Button
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  login();
                }}
                type="submit"
              >
                LOGIN
              </Button>
            </form>
            {/* <Grid container justifyContent={"center"} marginTop={"30px"}>
              <Grid item xs={12} md={10} marginBottom={"6px"}>
                <Button
                  variant="outlined"
                  fullWidth
                  size="large"
                  style={{ textTransform: "unset" }}
                  startIcon={<Google />}
                  color="secondary"
                >
                  Sign in with Google
                </Button>
              </Grid>
              <Grid item xs={12} md={10} marginBottom={"6px"}>
                <Button
                  variant="outlined"
                  fullWidth
                  size="large"
                  style={{ textTransform: "unset" }}
                  startIcon={<Facebook />}
                  color="secondary"
                >
                  Sign in with Facebook
                </Button>
              </Grid>
              <Grid item xs={12} md={10} marginBottom={"6px"}>
                <Button
                  variant="outlined"
                  fullWidth
                  style={{ textTransform: "unset" }}
                  size="large"
                  startIcon={<Apple />}
                  color="secondary"
                >
                  Sign in with Apple
                </Button>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid
          item
          xs={0}
          display={{ sm: "none", md: "block" }}
          md={6}
          style={{
            height: '100vh', // Establece la altura al 100% de la ventana
            backgroundImage: 'url("/static/media/login.a6d359c6.png")', // Reemplaza con la URL de tu imagen
            backgroundSize: 'cover', // Ajusta el tamaño de la imagen para que cubra completamente el fondo
            backgroundPosition: 'center', // Centra la imagen
            padding: 0,
          }}
        >

        </Grid>
      </Grid>
    </>
  );
}
