import * as React from "react";
import Layout from "../../components/Layout";
import imgExample from "../../assets/img/imgEarth.png";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import VideocamSharpIcon from "@mui/icons-material/VideocamSharp";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";

export default function Index() {
  const [t] = useTranslation("global");
  const [eventName, setEventName] = React.useState("Event Name 01");
  const [timeOptionStart, setTimeOptionStart] = React.useState("pm");
  const [timeOptionEnd, setTimeOptionEnd] = React.useState("pm");

  return (
    <Layout isHome={true}>
      <Card>
        <CardContent>
          <h1> {t("page.event.eventName")}</h1>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField variant="outlined" value={eventName} fullWidth />
            </Grid>
          </Grid>


          <Grid container spacing={2} mt={3} alignItems={"end"}>
            <Grid item md={6} lg={3} xs={12}>
              <Typography color={"#595959"}>
                {t("page.event.eventStart")}
              </Typography>
              <TextField
                variant="outlined"
                type="date"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={3}
              xs={12}
              display={"flex"}
              justifyContent={"space-around"}
            >
              <TextField
                variant="outlined"
                type="time"
                size="small"
                style={{ marginRight: "5px", minWidth: "80px" }}
              />
              <FormControl fullWidth>
                <Select
                  size="small"
                  value={timeOptionStart}
                  onChange={(e) => setTimeOptionStart(e.target.value)}
                >
                  <MenuItem value={"pm"}> {t("page.event.pm")}</MenuItem>
                  <MenuItem value={"am"}> {t("page.event.am")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} lg={3} xs={12}>
              <Typography color={"#595959"}>
                {t("page.event.eventEnd")}
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                type="date"
                fullWidth
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={3}
              xs={12}
              display={"flex"}
              justifyContent={"space-around"}
            >
              <TextField
                variant="outlined"
                type="time"
                size="small"
                style={{ marginRight: "5px", minWidth: "80px" }}
              />
              <FormControl fullWidth>
                <Select
                  size="small"
                  value={timeOptionEnd}
                  onChange={(e) => setTimeOptionEnd(e.target.value)}
                >
                  <MenuItem value={"pm"}> {t("page.event.pm")}</MenuItem>
                  <MenuItem value={"am"}> {t("page.event.am")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={3} alignItems={"end"}>
            <Grid item md={6} lg={3} xs={12}>
              <Typography color={"#595959"}>
                {t("page.event.eventStart")}
              </Typography>
              <TextField
                variant="outlined"
                type="date"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={3}
              xs={12}
              display={"flex"}
              justifyContent={"space-around"}
            >
              <TextField
                variant="outlined"
                type="time"
                size="small"
                style={{ marginRight: "5px", minWidth: "80px" }}
              />
              <FormControl fullWidth>
                <Select
                  size="small"
                  value={timeOptionStart}
                  onChange={(e) => setTimeOptionStart(e.target.value)}
                >
                  <MenuItem value={"pm"}> {t("page.event.pm")}</MenuItem>
                  <MenuItem value={"am"}> {t("page.event.am")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} lg={3} xs={12}>
              <Typography color={"#595959"}>
                {t("page.event.eventEnd")}
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                type="date"
                fullWidth
              />
            </Grid>
            <Grid
              item
              md={6}
              lg={3}
              xs={12}
              display={"flex"}
              justifyContent={"space-around"}
            >
              <TextField
                variant="outlined"
                type="time"
                size="small"
                style={{ marginRight: "5px", minWidth: "80px" }}
              />
              <FormControl fullWidth>
                <Select
                  size="small"
                  value={timeOptionEnd}
                  onChange={(e) => setTimeOptionEnd(e.target.value)}
                >
                  <MenuItem value={"pm"}> {t("page.event.pm")}</MenuItem>
                  <MenuItem value={"am"}> {t("page.event.am")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          


          <Grid container spacing={2} mt={3}>
            <Grid item xs={12}>
              <Typography fontSize={22} marginBottom={2}>
                {t("page.event.zona")}
              </Typography>
              <Typography color={"#595959"} fontSize={22}>
                (GMT -04:00) Santiago
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={3}>
            <Grid item xs={12} md={6} lg={3}>
              <Button
                size="large"
                fullWidth
                startIcon={<VideocamSharpIcon />}
                endIcon={<CheckIcon />}
                variant="contained"
              >
                {t("page.event.main")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Button
                size="large"
                fullWidth
                startIcon={<WorkIcon />}
                endIcon={<CheckIcon />}
                variant="contained"
              >
                {t("page.event.workshops")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Button
                size="large"
                fullWidth
                color="secondary"
                style={{ color: "white" }}
                startIcon={<StickyNote2Icon />}
                variant="contained"
              >
                {t("page.event.expo")}
              </Button>
            </Grid>
            <Grid item xs={12} my={2}></Grid>
            <Grid item xs={12} md={6}>
              <Typography fontSize={22}> {t("page.event.logo")} </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                color="inherit"
                startIcon={<EditIcon />}
              >
                {t("page.event.change")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <img
                src={imgExample}
                alt="banner"
                width="691"
                height="251"
                style={{ height: "auto", maxWidth: "100%" }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Layout>
  );
}
