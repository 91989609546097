import { BASE_URL } from "./config";
import { callApi, callApiFromData, callApiInit, callApiLogin } from "./utils";
const urlEndPoint = BASE_URL;

function getToken() {
  const token = JSON.parse(localStorage.getItem("aet"));
  return token.access_token;
}

//SESSION TOKEN
export const apiGetSession = async () => {
  const url = `${urlEndPoint}api/v1/auth/init`;
  const json = await callApiInit(url, "GET", null, null);
  return json;
};

//LOGIN
export const apiLogin = async (requestData) => {
  const url = `${urlEndPoint}api/v3/auth/signin`;
  const json = await callApiLogin(url, "POST", null, requestData);
  return json;
};

