import * as React from "react";
import imgExample from "../assets/img/imgEarth.png";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import VideocamSharpIcon from "@mui/icons-material/VideocamSharp";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import { apiUploadImagen } from "../apis/Services/OrganizationService";
import moment from "moment";
moment.utc();

export default function AddEvent(props) {
  const { setOpen, save } = props;
  const [t] = useTranslation("global");
  const [eventName, setEventName] = React.useState("");
  const [eventDescription, setEventDescription] = React.useState("");
  const [eventStart, setEventStart] = React.useState("");
  const [eventEnd, setEventEnd] = React.useState("");
  const [eventTimeS, setEventTimeS] = React.useState("");
  const [eventTimeE, setEventTimeE] = React.useState("");
  const [timeOptionStart, setTimeOptionStart] = React.useState("pm");
  const [timeOptionEnd, setTimeOptionEnd] = React.useState("pm");
  const [imgEvent, setImgEvent] = React.useState(imgExample);
  const [mainStageSession, setMainStageSession] = React.useState(false);
  const [workshop, setWorkshop] = React.useState(false);
  const [expo, setExpo] = React.useState(false);

  const [error, setError] = React.useState({
    name: false,
    eStart: false,
    timeStart: false,
    eEnd: false,
    timeEnd: false,
  });

  const sendData = () => {
    let _error = {
      name: false,
      eStart: false,
      timeStart: false,
      eEnd: false,
      timeEnd: false,
    };
    if (!eventName) {
      _error.name = true;
    }
    if (!eventStart) {
      _error.eStart = true;
    }
    if (!eventEnd) {
      _error.eEnd = true;
    }
    if (!eventTimeS) {
      _error.timeStart = true;
    }
    if (!eventTimeE) {
      _error.timeEnd = true;
    }
    if (
      _error.name ||
      _error.eStart ||
      _error.eEnd ||
      _error.timeEnd ||
      _error.timeStart
    ) {
      setError(_error);
    } else {
      setError(_error);
      let json = {
        logo: imgEvent,
        name: eventName,
        start: moment(`${eventStart} ${eventTimeS}`).utcOffset(0).format(), // Formatea la fecha de inicio en UTC+0
        end: moment(`${eventEnd} ${eventTimeE}`).utcOffset(0).format(),     // Formatea la fecha de fin en UTC+0
        description: eventDescription,
        status: "not public",
        isPrivate: checked,
        type: [],
      };
      if (mainStageSession) {
        json.type.push({ type: "Main Stage Sessions" });
      }
      if (expo) {
        json.type.push({ type: "Expo" });
      }
      if (workshop) {
        json.type.push({ type: "Workshop" });
      }



      setOpen(false);
      save(json);
    }
  };

  async function readURL(e) {

    const file = e.target.files[0];
    const uploadImagen = await apiUploadImagen(file)

    const url = "https://eventosappstorage.blob.core.windows.net/eventos/" + uploadImagen.name
    setImgEvent(url)



    // let input = e.target;
    // if (input.files && input.files[0]) {
    //   var reader = new FileReader();

    //   reader.onload = function (e) {
    //     document.getElementById("imgPreview").src = e.target.result;
    //   };
    //   setImgEvent(await convertBase64(input.files[0]));
    //   reader.readAsDataURL(input.files[0]);
    // }
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const label = { inputProps: { 'aria-label': t("page.myevents.Noprivate") } };
  const [checked, setChecked] = React.useState(false);


  return (
    <Box component={"div"} padding={2} minWidth={"50vw"} maxWidth={"100%"}>
      <h1> {t("page.event.eventName")}</h1>
      <Grid container maxWidth={900}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <TextField
              variant="outlined"
              value={eventName}
              fullWidth
              onChange={(e) => setEventName(e.target.value)}
              name="eventName"
              error={error.name}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <Typography color={"#595959"}>
              {t("page.event.eventDescription")}
            </Typography>
            <TextField
              variant="outlined"
              value={eventDescription}
              fullWidth
              multiline
              rows={3}
              onChange={(e) => setEventDescription(e.target.value)}
              name="eventDescription"
              error={error.name}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <Typography color={"#595959"}>
              {t("page.myevents.private")}
            </Typography>
            <Switch checked={checked} onChange={(e) => { console.log(e.target.checked); setChecked(!checked) }} inputProps={{ 'aria-label': 'controlled' }} />
            {t("page.myevents.Noprivate")}

          </Grid>
        </Grid>


        <Grid container spacing={2} mt={3} alignItems={"end"}>
          <Grid item md={6} lg={3} xs={12}>
            <Typography color={"#595959"}>
              {t("page.event.eventStart")}
            </Typography>
            <TextField
              variant="outlined"
              type="date"
              size="small"
              fullWidth
              value={eventStart}
              onChange={(e) => { setEventStart(e.target.value); }}
              error={error.eStart}
            />
          </Grid>
          <Grid
            item
            md={6}
            lg={3}
            xs={12}
            display={"flex"}
            justifyContent={"space-around"}
          >
            <TextField
              variant="outlined"
              type="time"
              size="small"
              value={eventTimeS}
              onChange={(e) => setEventTimeS(e.target.value)}
              error={error.timeStart}
              style={{ marginRight: "20px", minWidth: "120px" }}
            />
            <FormControl fullWidth>
              <Select
                size="small"
                value={timeOptionStart}
                onChange={(e) => setTimeOptionStart(e.target.value)}
              >
                <MenuItem value={"pm"}> {t("page.event.pm")}</MenuItem>
                <MenuItem value={"am"}> {t("page.event.am")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>


          <Grid item md={6} lg={3} xs={12}>
            <Typography color={"#595959"}>
              {t("page.event.eventEnd")}
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              type="date"
              fullWidth
              value={eventEnd}
              onChange={(e) => setEventEnd(e.target.value)}
              error={error.eEnd}
            />
          </Grid>
          <Grid
            item
            md={6}
            lg={3}
            xs={12}
            display={"flex"}
            justifyContent={"space-around"}
          >
            <TextField
              variant="outlined"
              type="time"
              size="small"
              error={error.timeEnd}
              value={eventTimeE}
              onChange={(e) => setEventTimeE(e.target.value)}
              style={{ marginRight: "5px", minWidth: "120px" }}
            />
            <FormControl fullWidth>
              <Select
                size="small"
                value={timeOptionEnd}
                onChange={(e) => setTimeOptionEnd(e.target.value)}
              >
                <MenuItem value={"pm"}> {t("page.event.pm")}</MenuItem>
                <MenuItem value={"am"}> {t("page.event.am")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={6}>
          <Grid item xs={12} md={6} lg={4}>
            {mainStageSession ? (
              <Button
                size="large"
                fullWidth
                startIcon={<VideocamSharpIcon />}
                endIcon={<CheckIcon />}
                variant="contained"
                onClick={() => setMainStageSession(!mainStageSession)}
              >
                {t("page.event.main")}
              </Button>
            ) : (
              <Button
                size="large"
                fullWidth
                color="secondary"
                style={{ color: "white" }}
                startIcon={<VideocamSharpIcon />}
                variant="contained"
                onClick={() => setMainStageSession(!mainStageSession)}
              >
                {t("page.event.main")}
              </Button>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {workshop ? (
              <Button
                size="large"
                fullWidth
                startIcon={<WorkIcon />}
                endIcon={<CheckIcon />}
                variant="contained"
                onClick={() => setWorkshop(!workshop)}
              >
                {t("page.event.workshops")}
              </Button>
            ) : (
              <Button
                size="large"
                fullWidth
                startIcon={<WorkIcon />}
                color="secondary"
                style={{ color: "white" }}
                variant="contained"
                onClick={() => setWorkshop(!workshop)}
              >
                {t("page.event.workshops")}
              </Button>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {expo ? (
              <Button
                size="large"
                fullWidth
                endIcon={<CheckIcon />}
                startIcon={<StickyNote2Icon />}
                variant="contained"
                onClick={() => setExpo(!expo)}
              >
                {t("page.event.expo")}
              </Button>
            ) : (
              <Button
                size="large"
                fullWidth
                color="secondary"
                style={{ color: "white" }}
                startIcon={<StickyNote2Icon />}
                variant="contained"
                onClick={() => setExpo(!expo)}
              >
                {t("page.event.expo")}
              </Button>
            )}
          </Grid>
          <Grid item xs={12} my={2}></Grid>
          <Grid item xs={12} md={6}>
            <Typography fontSize={22}> {t("page.event.logo")} </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="inherit"
              startIcon={<EditIcon />}
              onClick={() => document.getElementById("file-input").click()}
            >
              {t("page.event.change")}
            </Button>
            <div className="image-upload">
              <input id="file-input" type="file" onChange={(e) => readURL(e)} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <img
              src={imgEvent ? imgEvent : imgExample}
              id="imgPreview"
              alt="banner"
              width="691"
              height="251"
              style={{ height: "auto", maxWidth: "100%" }}
            />
          </Grid>
        </Grid>
      </Grid>

      <div style={{ textAlign: "center", marginTop: "12px" }}>
        <Button
          color="secondary"
          style={{ minWidth: "120px", margin: "0 5px" }}
          variant="outlined"
          onClick={() => setOpen(false)}
        >
          {t("component.addSession.cancel")}
        </Button>
        <Button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            sendData();
          }}
          color="primary"
          style={{ minWidth: "120px", margin: "0 5px" }}
          variant="contained"
        >
          {t("component.addSession.save")}
        </Button>
      </div>
    </Box>
  );
}
