export function getToken() {
  // const token = JSON.parse(localStorage.getItem("aet"));
  const token = localStorage.getItem("aet");
  return token ? token : "";
}

export function getPublicToken() {
  // const token = JSON.parse(localStorage.getItem("out"));
  const token = localStorage.getItem("out");
  return token ? token : "";
}
