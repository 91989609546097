const dotenv = require("dotenv");

dotenv.config();

const env_status = process.env.REACT_APP_ENV_STATUS;

if (!env_status) {
  console.log(`⚠️  Couldn't find .env file  ⚠️: ${env_status}`);
} else {
  console.log(`⚠️  The .env file is  ⚠️: ${env_status}`);
}

const config = {
  BASE_URL: process.env.REACT_APP_BASEURL || "https://eventos-api.superapis.io/",
 
  JWT_SECRET:
    process.env.REACT_APP_JWT_SECRET || "j4enqvfg1dj0q8nf121w429f3b6p7i7g",
};

const { BASE_URL, JWT_SECRET } = config;

// console.log("1-jwt_secret", JWT_SECRET);

//DESCYPHER
let b = 0;
let des = "";
try {
  let dat = localStorage.getItem("session");

  while (b < dat.length - 32) {
    des = des + dat.charAt(b);
    b = b + 33;
  }
} catch (error) {
  des = JWT_SECRET;
}

const DESCYPHERED = des;

// console.log("2-jwt_secret", DESCYPHER);

module.exports = {
  BASE_URL,
  JWT_SECRET: DESCYPHERED,
  
};
