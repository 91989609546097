import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Timer(props) {
  const { deadline } = props;
  const [t] = useTranslation("global");
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const getTime = () => {
    const time = Date.parse(new Date(deadline)) - Date.now();

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  React.useEffect(() => {
    if (!deadline) return;
    const time = Date.parse(new Date(deadline)) - Date.now();
    if (time < 0) return;
    const interval = setInterval(() => getTime(deadline), 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="containerTimer" >
      <div>
        <Typography className="fontSizeTimer">
          {days < 10 ? "0" + days : days}
        </Typography>
        <Typography className="fontSizeText">
          {t("component.time.day")}
        </Typography>
      </div>
      <div>
        <Typography className="fontSizeTimer">
          {hours < 10 ? "0" + hours : hours}
        </Typography>{" "}
        <Typography className="fontSizeText">
          {t("component.time.hour")}
        </Typography>
      </div>
      <div>
        <Typography className="fontSizeTimer">
          {minutes < 10 ? "0" + minutes : minutes}
        </Typography>
        <Typography className="fontSizeText">
          {t("component.time.min")}
        </Typography>
      </div>

      <div>
        <Typography className="fontSizeTimer">
          {seconds < 10 ? "0" + seconds : seconds}
        </Typography>
        <Typography className="fontSizeText">
          {t("component.time.second")}
        </Typography>
      </div>
    </div>
  );
}
