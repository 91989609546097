import { BASE_URL } from '../config';
import { callApi, callApiInit, callApiLogin } from '../utils';
import { getToken, getPublicToken } from './token';

// ++++++++ Event ++++++++ //

// GET ALL
export const getSponsorsList = async (eventId) => {
  const url = `${BASE_URL}api/v1/sponsors/all/${eventId}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

// GET one event
export const getSponsors = async (id) => {
  const url = `${BASE_URL}api/v1/sponsors/${id}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

//SAVE
export const saveSponsors = async (json, eventId) => {
  const url = `${BASE_URL}api/v1/sponsors/${eventId}`;
  const jsonResult = await callApi(url, 'POST', getToken(), json);
  return jsonResult;
};

export const apieditSponsors = async (json, idSponsor) => {
  const url = `${BASE_URL}api/v1/sponsors/${idSponsor}`;
  const jsonResult = await callApi(url, 'PUT', getToken(), json);
  return jsonResult;
};

export const apideleteSponsors = async (idSponsor) => {
  const url = `${BASE_URL}api/v1/sponsors/${idSponsor}`;
  const jsonResult = await callApi(url, 'DELETE', getToken(), null);
  return jsonResult;
};
