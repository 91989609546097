import { BASE_URL } from '../config';
import { callApi, callApiInit, callApiLogin } from '../utils';
import { getToken, getPublicToken } from './token';

// ++++++++ Event ++++++++ //

// GET ALL
export const getEventList = async (idOrganization) => {
  const url = `${BASE_URL}api/v1/event/all/${idOrganization}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

// GET one event
export const getEvent = async (id) => {
  const url = `${BASE_URL}api/v1/event/${id}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

//SAVE
export const saveEvent = async (json, idOrganization) => {
  const url = `${BASE_URL}api/v1/event/${idOrganization}`;
  const jsonResult = await callApi(url, 'POST', getToken(), json);
  return jsonResult;
};

export const apieditEvent = async (json, idEvent) => {
  const url = `${BASE_URL}api/v1/event/${idEvent}`;
  const jsonResult = await callApi(url, 'PUT', getToken(), json);
  return jsonResult;
};

export const apiUpdateEventStatus = async (json, idEvent) => {
  const url = `${BASE_URL}api/v1/event/publish/${idEvent}`;
  const jsonResult = await callApi(url, 'PUT', getToken(), json);
  return jsonResult;
};

export const getTagsList = async () => {
  const url = `${BASE_URL}api/v1/tag/all`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const getVisitedAll = async (eventId) => {
  const url = `${BASE_URL}api/v1/inscription/${eventId}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const getAllDoc = async (sessionId) => {
  const url = `${BASE_URL}api/v1/event/all/doc/${sessionId}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const deleteIDDoc = async (docId) => {
  const url = `${BASE_URL}api/v1/event/doc/${docId}`;
  const json = await callApi(url, 'DELETE', getToken(), null);
  return json;
};


export const apiinvitedUser = async (event, body) => {
  const url = `${BASE_URL}api/v1/inscription/invited/${event}`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};


