import React, { useState, useEffect } from 'react';
import { CircularProgress, Container, Typography } from '@mui/material';

function LoadingView() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simula una carga asincrónica
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, []);

    return (
        <Container maxWidth="sm">


            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                <CircularProgress color="primary" />
            </div>

        </Container>
    );
}

export default LoadingView;
