import * as React from "react";
import Layout from "../../components/Layout";
import imgExample from "../../assets/img/imgEarth.png";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import VideocamSharpIcon from "@mui/icons-material/VideocamSharp";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import { apiUploadImagen } from "../../apis/Services/OrganizationService";
import {
  apieditEvent,
  getEvent,
  saveEvent,
} from "../../apis/Services/EventService";
import { localDEncrypted } from "../../apis/utils";
import moment from "moment";
import { modalMsgFunction } from "../../assets/library/GlobalFunction";
import ModalCustom from "../../components/ModalCustom";
import LoadingView from "../../components/Loading";

moment.utc();

const EventsDetail = () => {
  const [loading, setloading] = React.useState(true);
  const [t] = useTranslation("global");
  const [eventName, setEventName] = React.useState("Event Name 01");
  const [timeOptionStart, setTimeOptionStart] = React.useState("pm");
  const [timeOptionEnd, setTimeOptionEnd] = React.useState("pm");
  const [profileImg, setProfileImg] = React.useState(null);
  const [mainStageSession, setMainStageSession] = React.useState(false);
  const [workshop, setWorkshop] = React.useState(false);
  const [expo, setExpo] = React.useState(false);
  const [modalConfig, setModalConfig] = React.useState(modalMsgFunction(false));
  const [open, setOpen] = React.useState(false);
  const [idEvent, setidEvent] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const [error, setError] = React.useState({
    name: false,
    website: false,
    email: false,
  });

  const [data, setData] = React.useState({
    logo: "",
    name: "",
    start: "",
    end: "",
    description: "",
    status: "",
    type: [],
  });

  const sendData = () => {
    let _error = {
      name: false,
      eStart: false,
      timeStart: false,
      eEnd: false,
      timeEnd: false,
    };
    if (!data.name) {
      _error.name = true;
    }
    if (!data.start) {
      _error.eStart = true;
    }
    if (!data.end) {
      _error.eEnd = true;
    }

    if (_error.name || _error.eStart || _error.eEnd) {
      setError(_error);
    } else {
      setError(_error);
      let json = {
        logo: data.logo,
        name: data.name,
        start: moment(data.start).utc().format(), // Formatea la fecha de inicio en UTC+0
        end: moment(data.end).utc().format(), // Formatea la fecha de fin en UTC+0
        description: data.description,
        status: "not public",
        type: [],
        isPrivate: checked
      };

      if (mainStageSession) {
        json.type.push({ type: "Main Stage Sessions" });
      }
      if (expo) {
        json.type.push({ type: "Expo" });
      }
      if (workshop) {
        json.type.push({ type: "Workshop" });
      }

      setOpen(false);
      editEvent(json);
    }
  };

  const editEvent = (editEvent) => {
    setModalConfig(modalMsgFunction(true, 0, "Error", "Delete event Error"));
    apieditEvent(editEvent, idEvent)
      .then((result) => {
        setModalConfig(modalMsgFunction(true, 1, "OK", "Event Update"));
        getData();
      })
      .catch((err) => {
        setModalConfig(
          modalMsgFunction(true, 2, "Error", "Error update Event")
        );
      });
  };

  React.useEffect(() => {
    getData();
  }, []);

  async function getData() {
    let idEvent = localStorage.getItem("selectEvent");
    let id = localDEncrypted(idEvent);
    const eventDetail = await getEvent(id);
    console.log(eventDetail)
    setidEvent(id);
    setData({
      logo: eventDetail.logo,
      name: eventDetail.name,
      start: eventDetail.start,
      end: eventDetail.end,
      description: eventDetail.description,
      status: eventDetail.status,
      type: eventDetail.datatype,
      isPrivate: eventDetail.isPrivate,
    });

    setChecked(eventDetail.isPrivate)

    //type check
    const mainstage = eventDetail.datatype.find(
      (value) => value.name === "Main Stage Sessions"
    );
    setMainStageSession(mainstage);
    const workshops = eventDetail.datatype.find(
      (value) => value.name === "Workshop"
    );
    setWorkshop(workshops);
    const expo = eventDetail.datatype.find((value) => value.name === "Expo");
    setExpo(expo);

    setloading(false);
  }

  async function readURL(e) {
    setloading(true);
    const file = e.target.files[0];
    const uploadImagen = await apiUploadImagen(file);
    const url =
      "https://eventosappstorage.blob.core.windows.net/eventos/" +
      uploadImagen.name;
    setData({ ...data, logo: url });
    setloading(false);
  }

  const closeModal = (type) => {
    setModalConfig({ ...modalConfig, open: false });
  };
  return (
    <>
      <ModalCustom modalConfig={modalConfig} setOpen={closeModal} />
      <Layout>
        <Card>
          {loading ? (
            <LoadingView />
          ) : (
            <>
              <CardContent>
                <h1> {t("page.event.eventName")}</h1>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      variant="outlined"
                      value={data.name}
                      fullWidth
                      onChange={(e) => {
                        setData({ ...data, name: e.target.value });
                      }}
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      variant="outlined"
                      value={data.description}
                      fullWidth
                      multiline
                      onChange={(e) => {
                        setData({ ...data, description: e.target.value });
                      }}
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Typography color={"#595959"}>
                      {t("page.myevents.private")}
                    </Typography>
                    <Switch checked={checked} onChange={(e) => { console.log(e.target.checked); setChecked(!checked) }} inputProps={{ 'aria-label': 'controlled' }} />
                    {t("page.myevents.Noprivate")}


                  </Grid>


                </Grid>
                <Grid container spacing={2} mt={3} alignItems={"end"}>
                  <Grid item md={6} lg={3} xs={12}>
                    <Typography color={"#595959"}>
                      {t("page.event.eventStart")}
                    </Typography>
                    <TextField
                      variant="outlined"
                      type="date"
                      size="small"
                      // value={"2023-01-01"}
                      value={moment(data.start).format("YYYY-MM-DD")}
                      onChange={(e) => {
                        setData({
                          ...data,
                          start:
                            e.target.value +
                            " " +
                            moment(data.start).format("HH:mm:ss"),
                        });
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    lg={3}
                    xs={12}
                    display={"flex"}
                    justifyContent={"space-around"}
                  >
                    <TextField
                      variant="outlined"
                      type="time"
                      size="small"
                      value={moment(data.start).format("HH:mm")}
                      style={{ marginRight: "5px", minWidth: "120px" }}
                      onChange={(e) => {
                        setData({
                          ...data,
                          start:
                            moment(data.start).format("YYYY-MM-DD") +
                            " " +
                            e.target.value,
                        });
                      }}
                    />
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        // value={timeOptionStart}
                        onChange={(e) => setTimeOptionStart(e.target.value)}
                        value={
                          parseInt(moment(data.start).format("HH")) <= 12
                            ? "am"
                            : "pm"
                        }
                      >
                        <MenuItem value={"pm"}> {t("page.event.pm")}</MenuItem>
                        <MenuItem value={"am"}> {t("page.event.am")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} lg={3} xs={12}>
                    <Typography color={"#595959"}>
                      {t("page.event.eventEnd")}
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="date"
                      fullWidth
                      value={moment(data.end).format("YYYY-MM-DD")}
                      onChange={(e) => {
                        setData({
                          ...data,
                          end:
                            e.target.value +
                            " " +
                            moment(data.end).format("HH:mm:ss"),
                        });
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    lg={3}
                    xs={12}
                    display={"flex"}
                    justifyContent={"space-around"}
                  >
                    <TextField
                      variant="outlined"
                      type="time"
                      size="small"
                      style={{ marginRight: "5px", minWidth: "120px" }}
                      value={moment(data.end).format("HH:mm")}
                      onChange={(e) => {
                        setData({
                          ...data,
                          end:
                            moment(data.end).format("YYYY-MM-DD") +
                            " " +
                            e.target.value +
                            ":00",
                        });
                      }}
                    />
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        // value={timeOptionEnd}
                        onChange={(e) => setTimeOptionEnd(e.target.value)}
                        value={
                          parseInt(moment(data.end).format("HH")) <= 12
                            ? "am"
                            : "pm"
                        }
                      >
                        <MenuItem value={"pm"}> {t("page.event.pm")}</MenuItem>
                        <MenuItem value={"am"}> {t("page.event.am")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {/* <Grid container spacing={2} mt={3}>
                  <Grid item xs={12}>
                    <Typography fontSize={22} marginBottom={2}>
                      {t("page.event.zona")}
                    </Typography>
                    <Typography color={"#595959"} fontSize={22}>
                      (GMT 00:00) Universal
                    </Typography>
                  </Grid>
                </Grid> */}
                <Grid container spacing={2} mt={3}>
                  <Grid item xs={12} md={6} lg={4}>
                    {mainStageSession ? (
                      <Button
                        size="large"
                        fullWidth
                        startIcon={<VideocamSharpIcon />}
                        endIcon={<CheckIcon />}
                        variant="contained"
                        onClick={() => setMainStageSession(!mainStageSession)}
                      >
                        {t("page.event.main")}
                      </Button>
                    ) : (
                      <Button
                        size="large"
                        fullWidth
                        color="secondary"
                        style={{ color: "white" }}
                        startIcon={<VideocamSharpIcon />}
                        variant="contained"
                        onClick={() => setMainStageSession(!mainStageSession)}
                      >
                        {t("page.event.main")}
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    {workshop ? (
                      <Button
                        size="large"
                        fullWidth
                        startIcon={<WorkIcon />}
                        endIcon={<CheckIcon />}
                        variant="contained"
                        onClick={() => setWorkshop(!workshop)}
                      >
                        {t("page.event.workshops")}
                      </Button>
                    ) : (
                      <Button
                        size="large"
                        fullWidth
                        startIcon={<WorkIcon />}
                        color="secondary"
                        style={{ color: "white" }}
                        variant="contained"
                        onClick={() => setWorkshop(!workshop)}
                      >
                        {t("page.event.workshops")}
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    {expo ? (
                      <Button
                        size="large"
                        fullWidth
                        endIcon={<CheckIcon />}
                        startIcon={<StickyNote2Icon />}
                        variant="contained"
                        onClick={() => setExpo(!expo)}
                      >
                        {t("page.event.expo")}
                      </Button>
                    ) : (
                      <Button
                        size="large"
                        fullWidth
                        color="secondary"
                        style={{ color: "white" }}
                        startIcon={<StickyNote2Icon />}
                        variant="contained"
                        onClick={() => setExpo(!expo)}
                      >
                        {t("page.event.expo")}
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12} my={2}></Grid>
                  <Grid item xs={12} md={6}>
                    <Typography fontSize={22}>
                      {" "}
                      {t("page.event.logo")}{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      color="inherit"
                      startIcon={<EditIcon />}
                      onClick={() =>
                        document.getElementById("file-input").click()
                      }
                    >
                      {t("page.event.change")}
                    </Button>
                    <div className="image-upload">
                      <input
                        id="file-input"
                        type="file"
                        accept=".jpg, .jpeg, .png, .gif"
                        onChange={(e) => readURL(e)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <img
                      src={data.logo}
                      id="imgPreview"
                      alt="banner"
                      width="691"
                      height="251"
                      style={{ height: "auto", maxWidth: "100%" }}
                    />
                  </Grid>

                  <Grid item xs={12} textAlign={"center"} mb={3}>
                    <Button
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        sendData();
                      }}
                      color="primary"
                      size="large"
                      style={{ minWidth: "240px", margin: "0 5px" }}
                      variant="contained"
                    >
                      {t("component.addSession.save")}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </>
          )}
        </Card>
      </Layout>
    </>
  );
};

export default EventsDetail;
