import * as React from "react";
import Layout from "../../components/Layout";
import { Button, Drawer, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TableSponsors from "../../components/TableSponsors";
import AddSponsorsView from "../../components/AddSponsorView";
import { localDEncrypted } from "../../apis/utils";
import {
  apideleteSponsors,
  getSponsorsList,
} from "../../apis/Services/Sponsors";
import { modalMsgFunction } from "../../assets/library/GlobalFunction";
import ModalCustom from "../../components/ModalCustom";
export default function Index() {
  const [t] = useTranslation("global");
  const [modalConfig, setModalConfig] = React.useState(modalMsgFunction(false));
  const [OpenFromEdit, setOpenFromEdit] = React.useState(false);
  const [openFormSponsors, setOpenFormSponsors] = React.useState(false);
  const [Reload, setReload] = React.useState(false);
  const [idEvent, setidEvent] = React.useState("");
  const [OpenFormSponsorsId, setOpenFormSponsorsId] = React.useState("");

  const [sponsortData, setsponsortData] = React.useState([]);
  const rows = [];

  const addSponsors = () => {
    setOpenFormSponsorsId("");
    setOpenFromEdit(true);
    setOpenFormSponsors(true);
  };

  const ViewSponsors = (id) => {
    setOpenFromEdit(false);
    setOpenFormSponsors(true);
    setOpenFormSponsorsId(id);
  };

  const EditSponsors = (id) => {
    setOpenFromEdit(true);
    setOpenFormSponsors(true);
    setOpenFormSponsorsId(id);
  };

  const DelSponsort = (id) => {
    deleteSponsor(id);
  };

  async function deleteSponsor(id) {

    setModalConfig(modalMsgFunction(true, 0, "Error", "Delete Speaker Error"));
    const deleteresp = await apideleteSponsors(id);
    setModalConfig(modalMsgFunction(true, 1, "OK", "Event Delete"));
    GetDataAll(idEvent);
  }

  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    if (Reload) {
      setReload(false);
      GetDataAll(idEvent);
    }
  }, [Reload]);

  async function getData() {
    let idEvent = localStorage.getItem("selectEvent");
    let id = localDEncrypted(idEvent);
    GetDataAll(id);
  }

  async function GetDataAll(event) {
    const data = await getSponsorsList(event);
    setsponsortData(data);

    setidEvent(event);
  }

  const saveNewSponsor = (newSponsor) => {
    setOpenFormSponsors(false);
  };

  const closeModal = () => {
    setModalConfig({ ...modalConfig, open: false });
  };

  return (
    <Layout>
      <ModalCustom modalConfig={modalConfig} setOpen={closeModal} />
      <Grid container>
        <Grid item xs={12} md={3}>
          <h1> {t("page.sponsor.title")}</h1>
        </Grid>
        <Grid item xs={12} md={9} className="containerButtonSponsors">
          {/* <Button
            variant="outlined"
            style={{ margin: "5px" }}
            size="medium"
            color="secondary"
            startIcon={<SettingsIcon />}
          >
            {t("page.sponsor.setting")}
          </Button> */}
          {/* <Button
            variant="outlined"
            style={{ margin: "5px" }}
            size="medium"
            color="secondary"
            endIcon={<KeyboardArrowDownIcon />}
          >
            {t("page.sponsor.action")}
          </Button> */}
          <Button
            color="primary"
            style={{ margin: "5px" }}
            size="medium"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={addSponsors}
          >
            {t("page.sponsor.add")}
          </Button>
        </Grid>
        <Grid item xs={12} mt={6}>
          <TableSponsors
            rows={sponsortData}
            ViewSponsors={ViewSponsors}
            EditSponsors={EditSponsors}
            deleteSponsor={DelSponsort}
          />
        </Grid>
      </Grid>

      <Drawer
        anchor={"right"}
        open={openFormSponsors}
        onClose={() => setOpenFormSponsors(false)}
      >
        <AddSponsorsView
          saveNewSponsor={saveNewSponsor}
          setOpenFormSponsors={setOpenFormSponsors}
          idEvent={idEvent}
          setReload={setReload}
          OpenFormSponsorsId={OpenFormSponsorsId}
          OpenFromEdit={OpenFromEdit}
          setModalConfig={setModalConfig}
        />
      </Drawer>
    </Layout>
  );
}
