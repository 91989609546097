import { BASE_URL } from "../config";
import { callApi } from "../utils";
import { getToken } from "./token";

// ++++++++ Event ++++++++ //

// GET ALL
export const getUserList = async () => {
  const url = `${BASE_URL}api/v2/users/all`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};


export const getUserListOrg = async (org) => {
  const url = `${BASE_URL}api/v2/users/org/${org}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};



// GET one user
export const getUser = async (id) => {
  const url = `${BASE_URL}api/v2/users/${id}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

// PUT one user
export const setUser = async (id, jsonRequest) => {
  const url = `${BASE_URL}api/v2/users/${id}`;
  const json = await callApi(url, "PUT", getToken(), jsonRequest);
  return json;
};


// PUT one user
export const apideleteUser = async (id) => {
  const url = `${BASE_URL}api/v2/users/${id}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};


