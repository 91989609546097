import React from 'react';


const alphabetColors = {
    A: '#FF5733', // Grupo 1: Rojo
    B: '#FF5733', // Grupo 1: Rojo
    C: '#FF5733', // Grupo 1: Rojo
    D: '#FF5733', // Grupo 1: Rojo
    E: '#FF5733', // Grupo 1: Rojo
    F: '#33FF57', // Grupo 2: Verde
    G: '#33FF57', // Grupo 2: Verde
    H: '#33FF57', // Grupo 2: Verde
    I: '#33FF57', // Grupo 2: Verde
    J: '#33FF57', // Grupo 2: Verde
    K: '#3357FF', // Grupo 3: Azul
    L: '#3357FF', // Grupo 3: Azul
    M: '#3357FF', // Grupo 3: Azul
    N: '#3357FF', // Grupo 3: Azul
    O: '#3357FF', // Grupo 3: Azul
    P: '#FFFF33', // Grupo 4: Amarillo
    Q: '#FFFF33', // Grupo 4: Amarillo
    R: '#FFFF33', // Grupo 4: Amarillo
    S: '#FFFF33', // Grupo 4: Amarillo
    T: '#FFFF33', // Grupo 4: Amarillo
    U: '#FF33FF', // Grupo 5: Magenta
    V: '#FF33FF', // Grupo 5: Magenta
    W: '#FF33FF', // Grupo 5: Magenta
    X: '#FF33FF', // Grupo 5: Magenta
    Y: '#FF33FF', // Grupo 5: Magenta
    Z: '#FF33FF', // Grupo 5: Magenta
};



const getColorForLetter = (letter) => {
    return alphabetColors[letter.toUpperCase()] || '#000000'; // Negro como color por defecto
};

const NameInitialsAvatar = ({ name, width, height }) => {
    console.log("part[0]", name)

    const initials = name.split(' ').map(part => part[0].toUpperCase()).join('');


    const color = getColorForLetter(initials[0]); // Usa solo la primera inicial para el color


    const avatarStyle = {
        display: 'inline-block',
        width: width ? width : '50px',
        height: height ? height : '50px',
        borderRadius: '50%',
        backgroundColor: color,
        color: 'white',
        textAlign: 'center',
        lineHeight: '50px',
        fontSize: '20px',
        fontWeight: 'bold',
    };

    return (
        <div style={avatarStyle}>
            {initials}
        </div>
    );
};

export default NameInitialsAvatar;
