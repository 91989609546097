import * as React from "react";
import Layout from "../../components/Layout";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import OrganizerEvents from "./OrganizerEvents";
import EventList from "./EventList";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditIcon from "@mui/icons-material/Edit";
import {
  getOrganizationList,
  createOrganization,
  getOrganization,
  editOrganization,
  deleteOrganization,
  apiUploadImagen,
  getOrganizationUserList,
  addOrganizationUserList,
  delOrganizationUser,
} from "../../apis/Services/OrganizationService";
import { modalMsgFunction } from "../../assets/library/GlobalFunction";
import ModalCustom from "../../components/ModalCustom";
import { getEventList, saveEvent } from "../../apis/Services/EventService";
import { finddataMeta, localEncrypted } from "../../apis/utils";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <> {children}</>}
    </div>
  );
}

export default function Index() {
  const [t] = useTranslation("global");
  const [open, setOpen] = React.useState(false);
  const [organizationList, setOrganizationList] = React.useState([]);
  const [tabActive, setTabActive] = React.useState(0); // 0=organizor 1= events List
  const [eventList, setEventList] = React.useState([]);
  const [eventName, setEventName] = React.useState("");

  const [eventId, setEventId] = React.useState(0);
  const [error, setError] = React.useState({
    name: false,
    website: false,
    email: false,
  });
  const [isEditModal, setIsEditModal] = React.useState(false);
  const [idOrganization, setIdOrganization] = React.useState(false);
  const [imgEvent, setImgEvent] = React.useState(null);
  const [organizationName, setOrganizationName] = React.useState("");
  const [organizationEmail, setOrganizationEmail] = React.useState("");
  const [organizationWeb, setOrganizationWeb] = React.useState("");
  const [organizationDescription, setOrganizationDescription] =
    React.useState("");
  const [modalConfig, setModalConfig] = React.useState(modalMsgFunction(false));
  const [superAdmin, setsuperAdmin] = React.useState(false);
  const [userOrg, setuserOrg] = React.useState([]);
  const [showuserOrg, setshowuserOrg] = React.useState(false);
  const [vinculeUserOrg, setvinculeUserOrg] = React.useState(false);
  const [organizationId, setorganizationId] = React.useState("");

  React.useEffect(() => {
    getOrganizationFunction();
    getMeta();
  }, []);

  async function getMeta() {
    const permitionSuperAdmin = await finddataMeta();
    const json = JSON.parse(permitionSuperAdmin);
    console.log("permitionSuperAdmin", json.superadmin);
    setsuperAdmin(json.superadmin);
  }

  async function userOrgGet(org) {
    setModalConfig(modalMsgFunction(true, 0));
    const userOrgData = await getOrganizationUserList(org);
    // console.log("userOrg", userOrgData)
    setuserOrg(userOrgData);
    setorganizationId(org);
    setshowuserOrg(true);
    setModalConfig(modalMsgFunction(false, 0));
  }

  const openUserOrg = async (id) => {
    userOrgGet(id);
  };

  async function delUserOrg(idUser) {
    const body = {
      id: idUser,
    };
    const deleteData = await delOrganizationUser(organizationId, body);

    setshowuserOrg(false);
  }

  const getOrganizationFunction = () => {
    getOrganizationList()
      .then((result) => {
        setOrganizationList(result);
      })
      .catch((err) => console.log("err", err));
  };

  const changeModalOrganizer = async (isEdit, id) => {
    if (!open) {
      if (isEdit) {
        setModalConfig(modalMsgFunction(true, 0));
        setIdOrganization(id);
        await getOrganization(id)
          .then((result) => {
            setOrganizationForm(
              result.name,
              result.description,
              result.website,
              result.email,
              result.image
            );

            setImgEvent(result.image);

            setIsEditModal(true);
            setModalConfig(modalMsgFunction(false, 0));
          })
          .catch((err) => console.log(err));
      } else {
        setIsEditModal(false);
        setImgEvent(false);
        setOrganizationForm();
      }
    }
    setOpen(!open);
  };

  const vinculeUserOrgHandle = async () => {
    const body = {
      email: organizationEmail,
      profile: 0,
    };
    const save = await addOrganizationUserList(organizationId, body);
    setvinculeUserOrg(false);
    setshowuserOrg(false);
  };

  const editOrganizationFunction = () => {
    let json = {
      name: organizationName,
      description: organizationDescription,
      email: organizationEmail,
      website: organizationWeb,
      image: imgEvent,
    };

    setModalConfig(modalMsgFunction(true, 0));
    editOrganization(json, idOrganization)
      .then((result) => {
        setOpen(false);
        setModalConfig(
          modalMsgFunction(true, 1, "OK", "Edit oganization success")
        );
        setOrganizationForm();
        getOrganizationFunction();
      })
      .catch((err) => {
        setModalConfig(modalMsgFunction(true, 2));
      });
  };

  const selectEvent = (id) => {
    setModalConfig(modalMsgFunction(true, 0));
    getEventList(id)
      .then((result) => {
        setTabActive(1);
        setIdOrganization(id);
        setEventList(result);
        localEncrypted(id, "org");
        closeModal();
      })
      .catch((err) => {
        console.log(err);
        closeModal();
      });
  };

  async function readURL(e) {
    const file = e.target.files[0];
    const uploadImagen = await apiUploadImagen(file);

    const url =
      "https://eventosappstorage.blob.core.windows.net/eventos/" +
      uploadImagen.name;
    setImgEvent(url);
  }

  const createOrganizationFunction = () => {
    let _error = { name: false, website: false, email: false };
    if (!organizationName) {
      _error.name = true;
    }
    if (!organizationWeb) {
      _error.website = true;
    }
    if (!organizationEmail) {
      _error.email = true;
    }
    setError(_error);
    if (_error.email || _error.name || _error.website) {
      return;
    }

    let json = {
      name: organizationName,
      description: organizationDescription,
      email: organizationEmail,
      website: organizationWeb,
      image: imgEvent,
    };

    setModalConfig(modalMsgFunction(true, 0));

    createOrganization(json)
      .then((result) => {
        setOpen(false);
        setModalConfig(
          modalMsgFunction(true, 1, "OK", "Create oganization success")
        );
        setOrganizationForm();
        getOrganizationFunction();
      })
      .catch((err) => {
        setModalConfig(modalMsgFunction(true, 2));
      });
  };

  const setOrganizationForm = (
    name = "",
    description = "",
    web = "",
    email = ""
  ) => {
    setOrganizationName(name);
    setOrganizationDescription(description);
    setOrganizationWeb(web);
    setOrganizationEmail(email);
    setError({ name: false, website: false, email: false });
  };

  const deleteOrganizationFunction = (id) => {
    setModalConfig(modalMsgFunction(true, 5));
    deleteOrganization(id)
      .then((result) => {
        getOrganizationFunction();
        setModalConfig(
          modalMsgFunction(true, 1, "OK", "Delete oganization success")
        );
      })
      .catch((err) => {
        setModalConfig(
          modalMsgFunction(true, 2, "Error", "Delete oganization Error")
        );
      });
  };

  //event create

  const createEventFunction = (newEvent) => {
    setModalConfig(
      modalMsgFunction(true, 0, "Error", "Delete oganization Error")
    );
    saveEvent(newEvent, idOrganization)
      .then((result) => {
        setModalConfig(modalMsgFunction(true, 1, "OK", "Event Create"));

        selectEvent(idOrganization);
      })
      .catch((err) => {
        setModalConfig(
          modalMsgFunction(true, 2, "Error", "Error create Event")
        );
      });
  };

  const closeModal = (type) => {
    setModalConfig({ ...modalConfig, open: false });
  };

  return (
    <>
      <ModalCustom modalConfig={modalConfig} setOpen={closeModal} />

      <Layout isHome={true} isAdmin={superAdmin}>
        <CustomTabPanel value={tabActive} index={0}>
          <OrganizerEvents
            organizationList={organizationList}
            changeModalOrganizer={changeModalOrganizer}
            deleteOrganizationFunction={deleteOrganizationFunction}
            selectEvent={selectEvent}
            openUserOrg={openUserOrg}
            userOrg={userOrg}
            showuserOrg={showuserOrg}
            setshowuserOrg={setshowuserOrg}
            delUserOrg={delUserOrg}
            setvinculeUserOrg={setvinculeUserOrg}
            t={t}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabActive} index={1}>
          <EventList
            setTabActive={setTabActive}
            organizationName={eventName}
            eventList={eventList}
            t={t}
            createEventFunction={createEventFunction}
          />
        </CustomTabPanel>
      </Layout>
      <Dialog
        open={open}
        onClose={changeModalOrganizer}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle textAlign={"center"}>
          {isEditModal
            ? t("page.myevents.editModal")
            : t("page.myevents.addModal")}
        </DialogTitle>
        <DialogContent>
          <Typography color={"#000"}>
            {t("page.myevents.organizationName")}
          </Typography>
          <TextField
            size="small"
            placeholder={t("page.myevents.organizationName")}
            fullWidth
            name="Organizor"
            error={error.name}
            variant="outlined"
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
          />
          <Typography color={"#000"} mt={2}>
            {t("page.myevents.organizationWeb")}
          </Typography>
          <TextField
            size="small"
            fullWidth
            name="website"
            error={error.website}
            variant="outlined"
            value={organizationWeb}
            onChange={(e) => setOrganizationWeb(e.target.value)}
          />

         


          <Typography color={"#000"} mt={2}>
            {t("page.myevents.organizationMail")}
          </Typography>
          <TextField
            size="small"
            placeholder="email@example.com"
            fullWidth
            name="email"
            error={error.email}
            variant="outlined"
            value={organizationEmail}
            onChange={(e) => setOrganizationEmail(e.target.value)}
          />
          <Typography color={"#000"} mt={2}>
            {t("page.myevents.organizationDescription")}
          </Typography>
          <TextField
            size="small"
            placeholder=""
            fullWidth
            multiline
            name="description"
            rows={3}
            variant="outlined"
            value={organizationDescription}
            onChange={(e) => setOrganizationDescription(e.target.value)}
          />
          <Typography my={2}>{t("page.myevents.organizationImage")}</Typography>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Button
                variant="contained"
                color="inherit"
                startIcon={<EditIcon />}
                onClick={() => document.getElementById("file-input").click()}
              >
                {t("page.myevents.organizationSelectImage")}
              </Button>
              <div className="image-upload">
                <input
                  id="file-input"
                  type="file"
                  onChange={(e) => readURL(e)}
                />
              </div>
            </div>
            {imgEvent ? (
              <img
                style={{
                  borderRadius: "25px",
                  maxWidth: "50px",
                  maxHeight: "50px",
                }}
                src={imgEvent}
                id="imgPreview"
                alt="image"
              />
            ) : (
              <AccountCircleIcon style={{ fontSize: "50px" }} />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {isEditModal ? (
            <Button
              variant="contained"
              onClick={editOrganizationFunction}
              fullWidth
            >
              {t("page.myevents.edit")}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={createOrganizationFunction}
              fullWidth
            >
              {t("page.myevents.save")}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={vinculeUserOrg}
        onClose={() => {
          setvinculeUserOrg(false);
        }}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle textAlign={"center"}>
          {isEditModal
            ? t("page.myevents.editModal")
            : t("page.myevents.addModal")}
        </DialogTitle>
        <DialogContent>
          <Typography color={"#000"} mt={2}>
            {t("page.myevents.email")}
          </Typography>
          <TextField
            size="small"
            placeholder="email@example.com"
            fullWidth
            name="email"
            error={error.email}
            variant="outlined"
            value={organizationEmail}
            onChange={(e) => setOrganizationEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={vinculeUserOrgHandle} fullWidth>
            {t("page.myevents.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
