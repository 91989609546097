import { Grid, Typography } from "@mui/material";

export default function Agenda() {
  const itemData = [
    {
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPc3Ibpct7xBEok5__AyEN6hTjYJZjRw1l3g&usqp=CAU",
      title: "University",
    },
    {
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPc3Ibpct7xBEok5__AyEN6hTjYJZjRw1l3g&usqp=CAU",
      title: "University",
    },
    {
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXPra1v8Y8sDi8Q_WReXNhwXlUo8iLzu5ZDEO50YSzi_2tyckF_x6b6Rv7hges2jjRfTc&usqp=CAU",
      title: "Health",
    },
  ];

  return (
    <Grid
      container
      marginTop={"20px"}
      spacing={2}
      direction="row"
      justifyContent="center"
      alignItems="stretch"
    >
      {itemData.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <div
            style={{
              border: "1px solid #0c0c0c",
              borderRadius: "16px",
              padding: "7px",
            }}
          >
            <div
              style={{
                minHeight: "160px",
                maxHeight: "160px",
                display: "grid",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "160px",
                  margin: "auto",
                }}
                src={item.img}
                alt={item.title}
              />
            </div>
            <Typography textAlign={"center"} marginTop={2}>
              {item.title}
            </Typography>
          </div>
        </Grid>
      ))}
    </Grid>
  );
}
