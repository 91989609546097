import { Grid, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function Speaker() {
  const data = [
    { img: "", name: "Patricia Manriquez" },
    { img: "", name: "Patricia Manriquez" },
    { img: "", name: "Patricia Manriquez" },
    { img: "", name: "Patricia Manriquez" },
  ];
  return (
    <Grid container spacing={3} marginTop={"20px"}>
      {data.map((item, index) => (
        <Grid item xs={6} md={3} key={index}>
          <div
            style={{
              border: "1px solid #0c0c0c",
              borderRadius: "16px",
              padding: "20px 7px",
              textAlign: "center",
            }}
          >
            {item.img ? (
              <img
                src={item.img}
                alt="Image"
                width={"50px"}
                height={"50px"}
                style={{ marginRight: "10px" }}
              />
            ) : (
              <AccountCircleIcon
                style={{ marginRight: "10px", color: "rgb(108, 107, 107)" }}
                sx={{ width: 50, height: 50 }}
              />
            )}
            <Grid>
              <Typography marginTop={2} fontWeight={"bold"}>
                {item.name}
              </Typography>
            </Grid>
          </div>
        </Grid>
      ))}
    </Grid>
  );
}
