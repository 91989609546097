import { Alert, Snackbar } from "@mui/material";

export default function AlertError(props) {
  const { error, setError, t } = props;

  function closeError() {
    setError({ ...error, open: false });
  }

  return (
    <Snackbar
      open={error.open}
      autoHideDuration={4000}
      onClose={() => closeError()}
    >
      <Alert
        onClose={() => closeError()}
        severity="error"
        sx={{ width: "100%" }}
      >
        {t("component.addSpeaker.incomplete")}
      </Alert>
    </Snackbar>
  );
}
