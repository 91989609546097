import * as React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";

import ReorderIcon from "@mui/icons-material/Reorder";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Toolbar, ListItemText, Box } from "@mui/material";
import SellIcon from "@mui/icons-material/Sell";
import logo from "../assets/img/advlogo.png";
import { NavLink } from "react-router-dom";

function LateralMenuHome({
  drawerWidth,
  container,
  mobileOpen,
  openMenuMobile,
  t,
  isAdmin,
}) {
  const menuValueElement = [
    {
      title: "component.menu.myEvents",
      icon: <ReorderIcon />,
      url: "/myevents",
    },

    // {
    //   title: "component.menu.user",
    //   icon: <ManageAccountsIcon />,
    //   url: "/users",
    // },

    // {
    //   title: "component.menu.visited",
    //   icon: <ManageAccountsIcon />,
    //   url: "/visited",
    // },


    //  {
    //   title: "component.menu.user",
    //   icon: <AccountCircleIcon />,
    //   url: "/profile",
    // },
  ];

  console.log("ISADMIN", isAdmin);

  if (isAdmin) {
    menuValueElement.push({
      title: "component.menu.user",
      icon: <ManageAccountsIcon />,
      url: "/Users",
    }, {
      title: "component.menu.tag",
      icon: <SellIcon />,
      url: "/tag",
    });
  }

  const menuList = (
    <div>
      <Toolbar>
        <img
          src={logo}
          alt="logo"
          width="512"
          height="512"
          className="imgIcon"
          style={{
            maxWidth: "100%",
            height: "auto",
            margin: "auto",
            maxHeight: "100px",
            width: "auto",
          }}
        />
      </Toolbar>

      <List>
        {menuValueElement.map((menu, index) => (
          <NavLink
            key={index}
            to={menu.url}
            className={({ isActive, isPending }) =>
              isActive ? "active" : isPending ? "pending" : ""
            }
            style={{ textDecoration: "none" }}
          >
            <ListItem className="containerMenuText" disablePadding>
              <ListItemButton>
                <ListItemIcon style={{ color: "#000" }}>
                  {menu.icon}
                </ListItemIcon>
                <ListItemText
                  style={{ color: "#000" }}
                  primary={t(menu.title)}
                />
              </ListItemButton>
            </ListItem>
          </NavLink>
        ))}
      </List>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={openMenuMobile}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {menuList}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        open
      >
        {menuList}
      </Drawer>
    </Box>
  );
}

export default LateralMenuHome;
