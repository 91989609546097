import * as React from "react";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomInputText from "./CustomInputText";
import imgDefault from "./../assets/img/PreviewSponsors.png";
import AlertError from "./AlertError";
import { apiUploadImagen } from "../apis/Services/OrganizationService";
import {
  ApiGetSpeakerid,
  ApiSpeakerSave,
  ApiSpekaerSaveUpdate,
} from "../apis/Services/Session";
import { modalMsgFunction } from "../assets/library/GlobalFunction";
import {
  apieditSponsors,
  getSponsors,
  saveSponsors,
} from "../apis/Services/Sponsors";
import ModalCustom from "./ModalCustom";

export default function AddSponsorsView(props) {
  const {
    saveNewSponsor,
    setOpenFormSponsors,
    idEvent,
    setReload,
    OpenFormSponsorsId,
    OpenFromEdit,
    setModalConfig,
  } = props;
  const [t] = useTranslation("global");
  const [name, setName] = React.useState("");
  const [profileImg, setProfileImg] = React.useState(null);
  const [bio, setBio] = React.useState("");

  const [error, setError] = React.useState({
    open: false,
    name: false,
    lastName: false,
    email: false,
  });

  function onChangeBio(e) {
    setBio(e);
  }

  async function readURL(e) {
    const file = e.target.files[0];
    const uploadImagen = await apiUploadImagen(file);
    const url =
      "https://eventosappstorage.blob.core.windows.net/eventos/" +
      uploadImagen.name;
    setProfileImg(url);
  }

  async function createSpeaker() {
    let errMsg = { open: false, name: false, lastName: false, email: false };
    if (!name) {
      errMsg.name = true;
    }

    //if (Object.keys(errMsg).length !== 0) {
    if (errMsg.name) {
      errMsg.open = true;
      setError({ ...errMsg });
      return;
    } else {
      setError({ ...errMsg });
    }

    let json = {
      name: name,
      description: bio ? bio : " ",
      logo: profileImg,
    };
    setModalConfig(modalMsgFunction(true, 0));

    if (OpenFormSponsorsId > 0) {
      const update = await apieditSponsors(json, OpenFormSponsorsId);
      if (update) {
        setModalConfig(modalMsgFunction(true, 1, "OK", "Event Update"));
      } else {
        setModalConfig(
          modalMsgFunction(true, 2, "Error", "Error update Session")
        );
      }

      setOpenFormSponsors(false);
      setReload(true);
    } else {
      const save = await saveSponsors(json, idEvent);
      if (save) {
        setModalConfig(modalMsgFunction(true, 1, "OK", "Event Update"));
      } else {
        setModalConfig(
          modalMsgFunction(true, 2, "Error", "Error update Session")
        );
      }

      setOpenFormSponsors(false);
      setReload(true);
    }
  }

  React.useEffect(() => {
    getDataID(OpenFormSponsorsId);
  }, [OpenFormSponsorsId]);

  async function getDataID(id) {
    if (parseInt(OpenFormSponsorsId) > 0) {
      const SponsorsDetail = await getSponsors(OpenFormSponsorsId);

      if (SponsorsDetail) {
        setName(SponsorsDetail.name);
        setBio(SponsorsDetail.description);
        setProfileImg(SponsorsDetail.logo);
      } else {
        setBio("");
        setName("");
        setProfileImg("");
      }
    } else {
      setBio("");
      setName("");
      setProfileImg("");
    }
  }

  return (
    <Box component={"div"} padding={2} minWidth={"50vw"} maxWidth={"100%"}>
      <form>
        <Typography>
          {t("component.addSponsor.name")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <TextField
          error={error.name}
          size="small"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          required
          disabled={!OpenFromEdit}
        />

        <Typography marginTop={1}>
          {t("component.addSponsor.description")}
        </Typography>
        <TextField
          size="small"
          rows={5}
          multiline
          fullWidth
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          disabled={!OpenFromEdit}
        />
        <Typography marginTop={1}>{t("component.addSponsor.logo")}</Typography>
        <div className="image-upload">
          <label htmlFor="file-input">
            <img
              id="imgPreview"
              src={profileImg ? profileImg : imgDefault}
              style={{ width: "100%", maxWidth: "400px" }}
            />
          </label>
          <input
            id="file-input"
            type="file"
            onChange={(e) => {
              if (OpenFromEdit) {
                readURL(e);
              }
            }}
          />
        </div>

        <div style={{ textAlign: "center", marginTop: "12px" }}>
          <Button
            color="secondary"
            style={{ minWidth: "120px", margin: "0 5px" }}
            variant="outlined"
            onClick={() => setOpenFormSponsors(false)}
          >
            {t("component.addSpeaker.cancel")}
          </Button>
          <Button
            disabled={!OpenFromEdit}
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              createSpeaker();
            }}
            color="primary"
            style={{ minWidth: "120px", margin: "0 5px" }}
            variant="contained"
          >
            {t("component.addSpeaker.save")}
          </Button>
        </div>
      </form>

      <AlertError error={error} t={t} setError={setError} />
    </Box>
  );
}
