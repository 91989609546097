import * as React from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomInputText from "./CustomInputText";
import imgDefault from "./../assets/img/Preview.png";
import AlertError from "./AlertError";
import { apiUploadImagen } from "../apis/Services/OrganizationService";
import {
  ApiGetSpeakerid,
  ApiSpeakerSave,
  ApiSpekaerSaveUpdate,
} from "../apis/Services/Session";
import { modalMsgFunction } from "../assets/library/GlobalFunction";

export default function AddSpeaker(props) {
  const {
    setOpenFormSpeaker,
    saveNewSpeaker,
    idEvent,
    setReload,
    OpenFormSpeakerId,
    OpenFromEdit,
    setModalConfig,
  } = props;
  const [t] = useTranslation("global");
  const [name, setName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [pronous, setPronous] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [profileImg, setProfileImg] = React.useState(null);
  const [linkedin, setLinkedin] = React.useState("");
  const [instagram, setInstagram] = React.useState("");
  const [twitter, setTwitter] = React.useState("");
  const [bio, setBio] = React.useState("");

  const [error, setError] = React.useState({
    open: false,
    name: false,
    lastName: false,
    email: false,
  });

  function onChangeBio(e) {
    setBio(e);
  }

  async function readURL(e) {
    const file = e.target.files[0];
    const uploadImagen = await apiUploadImagen(file);
    const url =
      "https://eventosappstorage.blob.core.windows.net/eventos/" +
      uploadImagen.name;
    setProfileImg(url);
  }

  function validateEmail(input) {
    input = input.replace(" ", "");
    var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (validRegex.test(input)) {
      //console.log("Valid email address!");
      return true;
    } else {
      //console.log("Invalid email address!");
      return false;
    }
  }

  async function createSpeaker() {
    let errMsg = { open: false, name: false, lastName: false, email: false };
    if (!name) {
      errMsg.name = true;
    }
    if (!lastName) {
      errMsg.lastName = true;
    }
    if (!validateEmail(email)) {
      errMsg.email = true;
    }
    //if (Object.keys(errMsg).length !== 0) {
    if (errMsg.name || errMsg.lastName || errMsg.email) {
      errMsg.open = true;
      setError({ ...errMsg });
      return;
    } else {
      setError({ ...errMsg });
    }

    let json = {
      firtname: name,
      lastname: lastName,
      email: email,
      pronouns: pronous,
      title: title,
      company: company,
      bibliography: bio,
      photo: profileImg,
      linkedin: linkedin,
      instagram: instagram,
      twitter: twitter,
    };
    modalMsgFunction(true, 0);
    if (OpenFormSpeakerId > 0) {
      const update = await ApiSpekaerSaveUpdate(json, OpenFormSpeakerId);
      if (update) {
        setModalConfig(modalMsgFunction(true, 1, "OK", "Event Update"));
      } else {
        setModalConfig(
          modalMsgFunction(true, 2, "Error", "Error update Session")
        );
      }

      setOpenFormSpeaker(false);
      setReload(true);
    } else {
      const save = await ApiSpeakerSave(json, idEvent);
      if (save) {
        setModalConfig(modalMsgFunction(true, 1, "OK", "Event Update"));
      } else {
        setModalConfig(
          modalMsgFunction(true, 2, "Error", "Error update Session")
        );
      }

      setOpenFormSpeaker(false);
      setReload(true);
    }
  }

  React.useEffect(() => {
    getDataID(OpenFormSpeakerId);
  }, [OpenFormSpeakerId]);

  async function getDataID(id) {

    if (parseInt(OpenFormSpeakerId) > 0) {
      const speakerDetail = await ApiGetSpeakerid(OpenFormSpeakerId);

      if (speakerDetail) {
        setName(speakerDetail.firtname);
        setLastName(speakerDetail.lastname);
        setEmail(speakerDetail.email);
        setPronous(speakerDetail.pronouns);
        setTitle(speakerDetail.title);
        setCompany(speakerDetail.company);
        setBio(speakerDetail.bibliography);
        setProfileImg(speakerDetail.photo);
        setLinkedin(speakerDetail.linkedin);
        setInstagram(speakerDetail.instagram);
        setTwitter(speakerDetail.twitter);
      } else {
        setName("");
        setLastName("");
        setEmail("");
        setPronous("");
        setTitle("");
        setCompany("");
        setBio("");
        setProfileImg("");
        setLinkedin("");
        setInstagram("");
        setTwitter("");
      }
    }
  }

  return (
    <Box
      component={"div"}
      padding={2}
      minWidth={"50vw"}
      maxWidth={"100%"}
      className="widthContextAdd"
    >
      <form>
        <Typography>
          {t("component.addSpeaker.name")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <TextField
          error={error.name}
          size="small"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          required
          disabled={!OpenFromEdit}
        />
        <Typography marginTop={1}>
          {t("component.addSpeaker.lastName")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <TextField
          error={error.lastName}
          size="small"
          variant="outlined"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          fullWidth
          required
          disabled={!OpenFromEdit}
        />
        <Typography marginTop={1}>
          {t("component.addSpeaker.email")}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <TextField
          error={error.email}
          size="small"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          required
          disabled={!OpenFromEdit}
        />
        <Typography marginTop={1}>
          {t("component.addSpeaker.pronouns")}
        </Typography>
        <TextField
          size="small"
          variant="outlined"
          value={pronous}
          onChange={(e) => setPronous(e.target.value)}
          fullWidth
          disabled={!OpenFromEdit}
        />
        <Typography marginTop={1}>{t("component.addSpeaker.title")}</Typography>
        <TextField
          size="small"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          disabled={!OpenFromEdit}
        />
        <Typography marginTop={1}>
          {t("component.addSpeaker.company")}
        </Typography>
        <TextField
          size="small"
          variant="outlined"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          fullWidth
          disabled={!OpenFromEdit}
        />
        <Typography marginTop={1}>{t("component.addSpeaker.bio")}</Typography>
        <Grid container>
          <Grid item xs={12}>
            <div style={{ maxWidth: "100%" }}>
              <CustomInputText value={bio} onChange={onChangeBio} />
            </div>
          </Grid>
        </Grid>
        <Typography marginTop={1}>
          {t("component.addSpeaker.profile")}
        </Typography>
        <div className="image-upload">
          <label htmlFor="file-input">
            <img id="imgPreview" src={profileImg ? profileImg : imgDefault} />
          </label>
          <input
            id="file-input"
            type="file"
            onChange={(e) => {
              if (OpenFromEdit) {
                readURL(e);
              }
            }}
          />
        </div>

        <Typography marginTop={2}>
          {t("component.addSpeaker.linkedin")}
        </Typography>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className="blackText">
                https://
              </InputAdornment>
            ),
          }}
          size="small"
          variant="outlined"
          value={linkedin}
          onChange={(e) => setLinkedin(e.target.value)}
          fullWidth
          disabled={!OpenFromEdit}
        />
        <Typography marginTop={1}>
          {t("component.addSpeaker.instagram")}
        </Typography>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className="blackText">
                https://
              </InputAdornment>
            ),
          }}
          size="small"
          variant="outlined"
          value={instagram}
          onChange={(e) => setInstagram(e.target.value)}
          fullWidth
          disabled={!OpenFromEdit}
        />
        <Typography marginTop={1}>
          {t("component.addSpeaker.twitter")}
        </Typography>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className="blackText">
                https://
              </InputAdornment>
            ),
          }}
          size="small"
          variant="outlined"
          value={twitter}
          onChange={(e) => setTwitter(e.target.value)}
          fullWidth
          disabled={!OpenFromEdit}
        />
        <div style={{ textAlign: "center", marginTop: "12px" }}>
          <Button
            color="secondary"
            style={{ minWidth: "120px", margin: "0 5px" }}
            variant="outlined"
            onClick={() => setOpenFormSpeaker(false)}
          >
            {t("component.addSpeaker.cancel")}
          </Button>
          <Button
            disabled={!OpenFromEdit}
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              createSpeaker();
            }}
            color="primary"
            style={{ minWidth: "120px", margin: "0 5px" }}
            variant="contained"
          >
            {t("component.addSpeaker.save")}
          </Button>
        </div>
      </form>

      <AlertError error={error} t={t} setError={setError} />
    </Box>
  );
}
