import { Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import TwitterIcon from "@mui/icons-material/Twitter";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

export default function About() {
  return (
    <Grid container spacing={2} paddingTop={3} paddingBottom={3}>
      <Grid item xs={12} md={6} textAlign={"center"}>
        <Typography margin={"auto"} maxWidth={"400px"}>
          is simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book. It has survived not only five centuries,
          but also the leap into electronic typesetting, remaining essentially
          unchanged. It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages, and more recently
          with desktop publishing software like Aldus PageMaker including
          versions of Lorem Ipsum.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography fontSize={"20px"} marginBottom={"16px"} fontWeight={"bold"}>
          Date & Time :
        </Typography>
        <div className="cardEventdetailFirst">
          <div style={{ display: "flex" }}>
            <IconButton
              style={{
                textTransform: "none",
                color: "#989898",
                marginRight: 2,
              }}
              size="small"
            >
              <CalendarTodayIcon fontSize="small" />
            </IconButton>
            <Typography color={"black"} marginRight={1}>
              Sep 1, 2023 at 6:00 PM - Sep 1, 2023 at 9:00 PM (America/Santiago)
            </Typography>
          </div>
        </div>
        <Button style={{ marginLeft: "30px" }}>Add to calendar</Button>
        <Divider />
        <Typography fontSize={"20px"} fontWeight={"bold"}>
          Share this event:
        </Typography>
        <IconButton className="buttonIconShare">
          <LinkedInIcon />
        </IconButton>
        <IconButton className="buttonIconShare">
          <FacebookIcon />
        </IconButton>
        <IconButton className="buttonIconShare">
          <TwitterIcon />
        </IconButton>
        <IconButton className="buttonIconShare">
          <EmailIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
