import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Timer from "../../components/Timer";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EditIcon from "@mui/icons-material/Edit";
import HandshakeIcon from "@mui/icons-material/Handshake";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import EventIcon from "@mui/icons-material/Event";
import AddIcon from "@mui/icons-material/Add";
import Layout from "../../components/Layout";
import { useNavigate } from "react-router-dom";
import { finddataMeta, localDEncrypted } from "../../apis/utils";
import {
  apiUpdateEventStatus,
  getEvent,
} from "../../apis/Services/EventService";
import {
  ApiGetSessionList,
  ApiGetSpeakerList,
} from "../../apis/Services/Session";
import moment from "moment";
import { getSponsorsList } from "../../apis/Services/Sponsors";
import NameInitialsAvatar from "../../components/AvatarName";

export default function Overviews() {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [event, setEvent] = React.useState({ name: "", id: 0 });
  const [speaker, setSpeaker] = React.useState([]);
  const [session, setsession] = React.useState([]);
  const [sessionItem, setsessionItem] = React.useState([]);
  const [sponsors, setSponsors] = React.useState([]);
  const [username, setusername] = useState('')
  // return <></>;

  useEffect(() => {
    loadData();
    getMeta()
  }, []);

  async function getMeta() {
    const permitionSuperAdmin = await finddataMeta();
    const json = JSON.parse(permitionSuperAdmin)
    setusername(json.name)
  }


  async function loadData() {
    let idEvent = localStorage.getItem("selectEvent");
    let id = localDEncrypted(idEvent);
    const eventlist = await getEvent(id);
    setEvent(eventlist);
    const listSpeaker = await ApiGetSpeakerList(id);
    setSpeaker(listSpeaker);

    const listSession = await ApiGetSessionList(id);
    setsession(listSession);

    const listSponsors = await getSponsorsList(id);
    setSponsors(listSponsors);
  }

  /* The above code is a useEffect hook in a React component. It is used to sort and update the state
  variable `sessionItem` based on the `session` prop. */
  useEffect(() => {
    let allTime = [];
    session.map((item) => {
      item.time.map((item2) => {
        allTime.push({
          time_start: item2.time_start,
          time_end: item2.time_end,
          eventId: item.id,
          eventDetail: item,
        });
      });
    });
    allTime.sort((a, b) => {
      const timeA = new Date(a.time_start);
      const timeB = new Date(b.time_start);
      return timeA - timeB;
    });
    setsessionItem(allTime);
  }, [session]);

  const redirect = (url) => {
    return navigate(url);
  };

  const publishEvent = async (id, status) => {
    if (status === "not public") {
      const data = {
        status: "public",
      };
      const update = await apiUpdateEventStatus(data, id);
      window.location.replace("/overviews");
    } else {
      const data = {
        status: "not public",
      };
      const update = await apiUpdateEventStatus(data, id);
      window.location.replace("/overviews");
    }
    // console.log(id);s
  };

  return (
    <Layout style={{ backgroundColor: "#f7f7fa" }}>
      <Grid
        container
        spacing={2}
        alignItems="stretch"
        style={{ backgroundColor: "#f7f7fa", width: "100%" }}
      >
        <Grid item xs={12}>
          <Card style={{ borderRadius: "30px", height: "100%" }}>
            <CardContent>
              <Grid
                container
                justifyContent={"space-around"}
                alignItems={"center"}
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  <h2 style={{ fontSize: "20px" }}>
                    {t("page.overviews.welcome", { name: username })}
                  </h2>
                  <h1 style={{ fontSize: "24px" }}>{event?.name}</h1>
                  <div style={{ textAlign: "left" }}>

                    <Button
                      size="medium"
                      color="primary"
                      variant="contained"
                      onClick={() => publishEvent(event.id, event.status)}
                      style={{
                        marginRight: "10px",
                        marginBottom: "10px",
                        minWidth: "140px",
                        backgroundColor:
                          event.status === "not public" ? "yellow" : "green",
                        color:
                          event.status === "not public" ? "black" : "white",
                      }}
                    >
                      {event.status === "not public" ? (
                        <>{t("component.button.publish")}</>
                      ) : (
                        <>{t("component.button.noPublish")}</>
                      )}
                    </Button>
                    <Button
                      size="medium"
                      color="secondary"
                      variant="outlined"
                      style={{
                        marginRight: "10px",
                        marginBottom: "10px",
                        minWidth: "140px",
                      }}
                      onClick={() => {
                        // console.log(process.env.REACT_APP_BASELANDING)
                        window.open(process.env.REACT_APP_BASELANDING + "detail/" + btoa(event.id), "_blank")

                      }
                      }
                    >
                      {t("component.button.preview")}
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    //xs={{ justifyContent: "center" }}
                    justifyContent={"end"}
                    alignItems={"center"}
                    display={"flex"}
                    maxWidth={"100%"}
                  >
                    {event.logo ? (
                      <img
                        src={event.logo}
                        alt="banner"
                        width="100%"
                        height="100%"
                        style={{
                          borderRadius: "15px",
                          height: "auto",
                          width: "100%",
                          margin: "auto",
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card style={{ borderRadius: "30px", height: "100%" }}>
            <CardContent>
              <h2 style={{ fontSize: "24px" }}>{t("page.overviews.timer")}</h2>
              {event.start ? <Timer deadline={event.start} /> : <></>}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ borderRadius: "30px", height: "100%" }}>
            <CardContent>
              <Grid container spacing={2} xs={12}>
                <Grid item xs={12} md={6} lg={6}>
                  <Button
                    fullWidth
                    variant="outlined"
                    size="large"
                    style={{ textTransform: "unset", borderRadius: "20px" }}
                    startIcon={<HandshakeIcon />}
                    onClick={() => redirect("/agenda")}
                    color="inherit"
                  >
                    {t("page.overviews.addSpeakers")}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Button
                    variant="outlined"
                    fullWidth
                    size="large"
                    style={{ textTransform: "unset", borderRadius: "20px" }}
                    startIcon={<DateRangeOutlinedIcon htmlColor="#6651b8" />}
                    color="inherit"
                    onClick={() => redirect("/agenda")}
                  >
                    {t("page.overviews.addSession")}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Button
                    variant="outlined"
                    fullWidth
                    size="large"
                    style={{ textTransform: "unset", borderRadius: "20px" }}
                    startIcon={<HandshakeIcon />}
                    color="inherit"
                    onClick={() => redirect("/sponsors")}
                  >
                    {t("page.overviews.addSponsor")}
                  </Button>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <Button
                    variant="outlined"
                    fullWidth
                    size="large"
                    style={{ textTransform: "unset", borderRadius: "20px" }}
                    startIcon={<StickyNote2OutlinedIcon />}
                    color="inherit"
                    onClick={() => redirect("/events")}
                  >
                    {t("page.overviews.editEvent")}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ borderRadius: "30px", height: "100%" }}>
            <CardContent>
              <div className="cardEventdetailFirst">
                <div style={{ display: "flex" }}>
                  <IconButton
                    style={{
                      textTransform: "none",
                      color: "#989898",
                      marginRight: 2,
                    }}
                    size="small"
                  >
                    <CalendarTodayIcon fontSize="small" />
                  </IconButton>
                  <Typography color={"black"} marginRight={1}>
                    {event.start
                      ? moment(event.start).format("DD/MM/YYYY HH:mm (Z)")
                      : ""}
                  </Typography>
                </div>
                <Button
                  variant="text"
                  style={{ textTransform: "none", color: "blue" }}
                  startIcon={<EditIcon fontSize="small" />}
                  onClick={() => redirect("/events")}
                  size="small"
                >
                  {t("page.overviews.edit")}
                </Button>
              </div>
              {/* <div className="cardEventdetailSecondMore">
                <IconButton
                  style={{
                    textTransform: "none",
                    color: "#989898",
                    marginRight: 2,
                  }}
                  size="small"
                >
                  <SellIcon fontSize="small" sx={{ color: "#989898" }} />
                </IconButton>
                <Typography color={"black"} marginRight={1}>
                  Virtual
                </Typography>
              </div> */}
              {/* <div className="cardEventdetailSecondMore">
                <IconButton
                  style={{
                    textTransform: "none",
                    color: "#989898",
                    marginRight: 2,
                  }}
                  size="small"
                >
                  <LocalAtmIcon fontSize="small" sx={{ color: "#989898" }} />
                </IconButton>
                <Typography color={"black"} marginRight={1}>
                  Free
                </Typography>
              </div> */}
              <div className="cardEventdetailSecondMore">
                {/* <IconButton
                  style={{
                    textTransform: "none",
                    color: "#989898",
                    marginRight: 2,
                  }}
                  size="small"
                >
                  <PeopleIcon fontSize="small" sx={{ color: "#989898" }} />
                </IconButton> */}
                <Typography color={"black"} marginRight={1}>
                  {event.description ? (
                    event.description.slice(0, 120) + "...."
                  ) : (
                    <></>
                  )}
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ borderRadius: "30px", height: "100%" }}>
            <CardContent>
              <Typography color={"black"}>
                {t("page.overviews.sponsor")}
              </Typography>
              <ImageList
                sx={{ width: "100%", height: "100%", paddingBottom: "20px" }}
                variant="quilted"
                cols={3}
                gap={8}
              >
                {sponsors.map((item, index) => (
                  <ImageListItem key={index}>
                    <img
                      style={{
                        height: "auto",
                        border: "1px solid #cfcccc",
                        width: "140px",
                        height: "120px",
                        borderRadius: "10px",
                      }}
                      src={`${item.logo}`}
                      srcSet={`${item.logo}`}
                      alt={item.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
                <ImageListItem key={9999}>
                  <Button
                    size="small"
                    style={{
                      textTransform: "none",
                      border: "1px solid #cfcccc",
                      color: "#3434fa",
                      fontWeight: "bold",
                      paddingBottom: "5px",
                      paddingTop: "5px",
                      width: "100%",
                    }}
                    startIcon={<AddIcon fontSize="small" />}
                    onClick={() => redirect("/sponsors")}
                  >
                    {t("page.overviews.addSponsor")}
                  </Button>
                </ImageListItem>
              </ImageList>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card style={{ borderRadius: "30px", height: "100%" }}>
            <CardContent>
              <Typography color={"black"}>
                {t("page.overviews.speaker")}
              </Typography>
              <ImageList
                sx={{ width: "100%", height: "100%", paddingBottom: "20px" }}
                variant="quilted"
                cols={3}
                gap={8}
              >

                {speaker.map((item, index) => {

                  return item.photo ? <>
                    <ImageListItem key={index} >
                      <img
                        style={{
                          height: "auto",
                          border: "1px solid #cfcccc",
                          width: "80px",
                          borderRadius: "50px",
                          textAlign: "center",
                        }}
                        src={`${item.photo}`}
                        srcSet={`${item.photo}`}
                        alt={item.firtname}
                        loading="lazy"
                      />
                    </ImageListItem>
                  </> : <>
                    <ImageListItem key={index} >
                      {item.firtname && (<NameInitialsAvatar name={item.firtname + item.lastname} width={"80px"} height={'80px'} />)}
                    </ImageListItem>
                  </>
                }
                )}


                <ImageListItem key={9999}>
                  <Button
                    size="small"
                    style={{
                      textTransform: "none",
                      border: "1px solid #cfcccc",
                      color: "#3434fa",
                      fontWeight: "bold",
                      paddingBottom: "5px",
                      paddingTop: "5px",
                      width: "100%",
                    }}
                    startIcon={<AddIcon fontSize="small" />}
                    onClick={() => redirect("/agenda")}
                  >
                    {t("page.overviews.addSpeakers")}
                  </Button>
                </ImageListItem>
              </ImageList>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Card style={{ borderRadius: "30px", width: "100%" }}>
            <CardContent>
              <div className="">
                <Typography color={"black"} marginRight={1}>
                  <strong style={{ fontSize: "20px" }}>
                    {t("page.overviews.agenda")}
                  </strong>
                  ({sessionItem.length} {t("page.overviews.sessions")})
                </Typography>
                <Button
                  style={{
                    textTransform: "none",
                    color: "blue",
                  }}
                  size="small"
                >
                  {t("page.overviews.view")}
                </Button>
              </div>
              <Grid container spacing={0} sx={12}>
                {sessionItem.map((item) => {
                  return (
                    <>
                      <Grid container spacing={0} sx={12} mb={3}>
                        <Grid item xs={2} md={1} lg={1} textAlign={"right"}>
                          <IconButton
                            size="medium"
                            className="backgroundColorCalendar"
                            sx={{
                              backgroundColor: "#f0f0f0",
                              borderRadius: "10px",
                              marginRight: "5px",
                            }}
                          >
                            <EventIcon />
                          </IconButton>
                        </Grid>
                        <Grid item xs={4} md={4} lg={4} textAlign={"left"}>
                          <Typography fontSize={"15px"}>
                            {moment(item.time_start).format("DD-MM-YYYY")}
                          </Typography>
                          <Typography fontSize={"16px"} fontWeight={"bold"}>
                            {moment(item.time_start).format("HH:mm")} -{" "}
                            {moment(item.time_end).format("HH:mm")}
                          </Typography>
                        </Grid>

                        <Grid item xs={6} md={7} lg={7}>
                          <Typography
                            marginBottom={0.5}
                            color={"black"}
                            className="hiddenText"
                            fontSize={"15px"}
                          >

                            {item.eventDetail.descriptionShort}
                          </Typography>
                          <Typography fontSize={"13px"} color={"#a2a2a2"}>
                            {item.eventDetail.provider}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout >
  );
}
