import { Button } from "@mui/material";
import { useNavigate, useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const navigate = useNavigate();

  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <Button variant="contained" color="primary" onClick={() => navigate("/")}>
        Go Home
      </Button>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
