import * as React from "react";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ReorderIcon from "@mui/icons-material/Reorder";
import GridViewIcon from "@mui/icons-material/GridView";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";

export default function Agenda() {
  const [selectDates, setSelectDates] = React.useState("all");
  const [viewData, setViewData] = React.useState("grid");
  const data = [
    {
      date: new Date(),
      content: [
        {
          title: "title",
          range: "4:00 PM - 5:00 PM",
          time: "60 Minutes",
          icon: "",
          name: "Patricia Manriquez",
          enteprise: "Super Apis",
          job: "CEO",
        },
        {
          title: "title",
          range: "4:00 PM - 5:00 PM",
          time: "60 Minutes",
          icon: "",
          name: "Patricia Manriquez",
          enteprise: "Super Apis",
          job: "CEO",
        },
        {
          title: "title",
          range: "4:00 PM - 5:00 PM",
          time: "60 Minutes",
          icon: "",
          name: "Patricia Manriquez",
          enteprise: "Super Apis",
          job: "CEO",
        },
        {
          title: "title",
          range: "4:00 PM - 5:00 PM",
          time: "60 Minutes",
          icon: "",
          name: "Patricia Manriquez",
          enteprise: "Super Apis",
          job: "CEO",
        },
      ],
    },
    {
      date: new Date(new Date().setDate(1)),
      content: [
        {
          title: "title 2",
          range: "4:00 PM - 5:00 PM",
          time: "60 Minutes",
          icon: "",
          name: "Patricia Manriquez",
          enteprise: "Super Apis",
          job: "CEO",
        },
      ],
    },
    {
      date: new Date(new Date().setDate(2)),
      content: [
        {
          title: "title 2",
          range: "4:00 PM - 5:00 PM",
          time: "60 Minutes",
          icon: "",
          name: "Patricia Manriquez",
          enteprise: "Super Apis",
          job: "CEO",
        },
        {
          title: "title 2",
          range: "4:00 PM - 5:00 PM",
          time: "60 Minutes",
          icon: "",
          name: "Patricia Manriquez",
          enteprise: "Super Apis",
          job: "CEO",
        },
      ],
    },
    {
      date: new Date(new Date().setDate(3)),
      content: [
        {
          title: "title 2",
          range: "4:00 PM - 5:00 PM",
          time: "60 Minutes",
          icon: "",
          name: "Patricia Manriquez",
          enteprise: "Super Apis",
          job: "CEO",
        },
        {
          title: "title 2",
          range: "4:00 PM - 5:00 PM",
          time: "60 Minutes",
          icon: "",
          name: "Patricia Manriquez",
          enteprise: "Super Apis",
          job: "CEO",
        },
        {
          title: "title 2",
          range: "4:00 PM - 5:00 PM",
          time: "60 Minutes",
          icon: "",
          name: "Patricia Manriquez",
          enteprise: "Super Apis",
          job: "CEO",
        },
      ],
    },
  ];

  const getDateFormat = (date) => {
    let days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let dayReturn = "";
    days.forEach((day, index) => {
      if (index == date.getDay()) {
        dayReturn = day;
      }
    });
    // let options = { weekday: "short", month: "short" };
    let options = { day: "2-digit", month: "short" };
    let month = new Intl.DateTimeFormat("en-US", options).format(date);
    let hour =
      date.getHours() < 12
        ? date.getHours().toString() + ":00 AM"
        : date.getHours().toString() + ":00 PM";
    let jsonReturn = { month, hour, day: dayReturn };

    return jsonReturn;
  };

  const control = {
    value: viewData,
    onChange: (item, i) => {
      setViewData(i);
    },
    exclusive: true,
  };

  return (
    <Grid container>
      <Grid item xs={12} marginTop={"20px"} textAlign={"right"}>
        <FormControl size="small" style={{ marginRight: "10px" }}>
          <Select
            value={selectDates}
            onChange={(item) => setSelectDates(item.target.value)}
          >
            <MenuItem value={"all"}>All Date</MenuItem>
            <MenuItem value={20}>All Date</MenuItem>
            <MenuItem value={30}>All Date</MenuItem>
          </Select>
        </FormControl>
        <ToggleButtonGroup {...control} variant="outlined" size="small">
          <ToggleButton value="grid">
            <GridViewIcon />
          </ToggleButton>
          <ToggleButton value="table">
            <ReorderIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12} marginTop={"10px"} textAlign={"right"}>
        <Button
          variant="outlined"
          color="secondary"
          style={{ marginRight: "10px" }}
        >
          <SearchIcon />
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<FilterAltIcon />}
        >
          Tracks & Tags
        </Button>
        <FormControlLabel
          value="start"
          style={{
            borderRadius: "10px",
            border: "1px solid #ccc",
            paddingLeft: "10px",
            paddingRight: "10px",
            marginRight: "0px",
          }}
          variant="outlined"
          control={<Switch color="primary" />}
          label="Show past sessions"
          labelPlacement="start"
        />
      </Grid>
      <Grid item xs={12}>
        <div className="page">
          {data.map((item, index) => (
            <div className="timeline" key={index}>
              <div className="timeline__group">
                <div className="timeline__year time">
                  <Typography> {getDateFormat(item.date).day}</Typography>
                  <Typography> {getDateFormat(item.date).month}</Typography>
                  <Typography>{getDateFormat(item.date).hour}</Typography>
                  <Typography> UTF-0</Typography>
                </div>
                <div className="timeline__cards">
                  <div className="timeline__card ">
                    <Grid
                      container
                      spacing={1}
                      alignItems={"stretch"}
                      justifyContent={"space-evenly"}
                    >
                      {item.content.map((card, index2) => (
                        <Grid
                          item
                          xs="auto"
                          key={index2}
                          width={"100%"}
                          minWidth={
                            item.content.length === 1
                              ? "100%"
                              : item.content.length === 2
                              ? "50%"
                              : item.content.length === 3
                              ? "33%"
                              : "50%"
                          }
                          marginBottom={"10px"}
                        >
                          <div className="containerCardAgenda">
                            <Grid
                              justifyContent={"space-between"}
                              display={"flex"}
                            >
                              <Typography fontWeight={"bold"}>
                                {card.title}
                              </Typography>
                              <Button
                                style={{
                                  background: "#000",
                                  borderRadius: "25px",
                                }}
                                variant="contained"
                              >
                                Bookmark
                              </Button>
                            </Grid>
                            <Typography fontWeight={600} marginBottom={"10px"}>
                              {card.range}
                            </Typography>
                            <Typography fontWeight={600} marginBottom={"10px"}>
                              {card.time}
                            </Typography>
                            <Grid display={"flex"} alignItems={"center"}>
                              {card.icon ? (
                                <img
                                  src={card.icon}
                                  alt="Image"
                                  width={"50px"}
                                  height={"50px"}
                                  style={{ marginRight: "10px" }}
                                />
                              ) : (
                                <AccountCircleIcon
                                  style={{ marginRight: "10px" }}
                                  sx={{
                                    width: 50,
                                    height: 50,
                                    color: "rgb(108, 107, 107)",
                                  }}
                                />
                              )}
                              <Grid>
                                <Typography fontWeight={"bold"}>
                                  {card.name}
                                </Typography>
                                <Typography fontWeight={"bold"}>
                                  {card.enteprise}
                                </Typography>
                                <Typography fontWeight={"bold"}>
                                  {card.job}
                                </Typography>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {/* <div className="timeline__group">
              <span className="timeline__year time" aria-hidden="true">
                2014
              </span>
              <div className="timeline__cards">
                <div className="timeline__card card">
                  <header className="card__header">
                    <time className="time" datetime="2008-07-14">
                      <span className="time__day">14</span>
                      <span className="time__month">Jul</span>
                    </time>
                  </header>
                  <div className="card__content">
                    <p>
                      Travels to France, Italy, Spain, and Peru. After
                      completing fashion editorial in Lima, prolongs stay to
                      make portraits of local people in a daylight studio
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="timeline__group">
              <span className="timeline__year time" aria-hidden="true">
                2016
              </span>
              <div className="timeline__cards">
                <div className="timeline__card card">
                  <header className="card__header">
                    <time className="time" datetime="2008-08-18">
                      <span className="time__day">28</span>
                      <span className="time__month">Aug</span>
                    </time>
                  </header>
                  <div className="card__content">
                    <p>
                      Upon moving to Brooklyn that summer, I began photographing
                      weddings in Chicago
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
        </div>
      </Grid>
    </Grid>
  );
}
