import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import imgDefault from "./../assets/img/PreviewSponsors.png";
import { apiUploadFile, apiUploadImagen } from '../apis/Services/OrganizationService';
import { Button, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { deleteIDDoc, getAllDoc } from '../apis/Services/EventService';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Link } from 'react-router-dom';
import moment from 'moment'
import DeleteIcon from "@mui/icons-material/Delete";


const FileUpload = (props) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const { idEventSession } = props;



  const handleFileChange = async (event) => {
    setIsImageUploading(true);
    const files = event.target.files;
    // const localToken = getTokenView(localStorage.getItem('session'));
    try {
      for (let file of files) {
        await apiUploadFile(file, idEventSession)
        // const url =
        //   "https://eventosappstorage.blob.core.windows.net/eventos/" +
        //   upload.name;
        // // setProfileImg(url);
        // setUploadedFiles(url);
      }
    } catch (error) {
      setIsImageUploading(false);
    }


    await getAllDocument()

    // setPhotoList(response);
    setIsImageUploading(false);
  };



  useEffect(() => {
    setIsImageUploading(true)
    getAllDocument()
  }, [idEventSession])


  async function deleteFile(id) {
    const deleteDoc = await deleteIDDoc(id)
    setIsImageUploading(true)
    getAllDocument()
  }
  async function getAllDocument() {
    const response = await getAllDoc(idEventSession);
    setUploadedFiles(response)
    setIsImageUploading(false)
  }
  const handleImageDragOver = (e) => {
    e.preventDefault();
  };

  const handleImageDrop = (e) => {
    e.preventDefault();
    // const droppedImage = e.dataTransfer.files[0];
    // setImage(droppedImage);
  };



  const FileIcon = ({ fileName }) => {
    const fileExtension = fileName.split('.').pop();
    let iconName;

    switch (fileExtension) {
      case 'pdf':
        return <PictureAsPdfIcon />; // Reemplaza con el nombre de clase para el ícono de PDF
        break;
      case 'docx':
        return <FileCopyIcon />; // Reemplaza con el nombre de clase para el ícono de PDF
        break;
      // Agrega más casos según sea necesario
      default:
        return <AttachFileIcon />
    }


  };




  const FileGrid = ({ files }) => {

    return (
      <Grid container spacing={2}>
        {files.map(file => {
          return (
            <Grid item xs={12} sm={12} md={12} key={file.id}>
              <Paper style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
                <FileIcon fileName={file.url} />
                <Typography variant="body1" style={{ marginLeft: '10px' }}>
                  <a href={`https://eventosappstorage.blob.core.windows.net/eventos/${file.url}`} target="_blank" rel="noopener noreferrer">
                    {moment(file.createdAt).format("YYYY-MM-DD") + "-" + file.id}
                  </a>
                </Typography>

                <Button onClick={() => {
                  deleteFile(file.id)
                }}><DeleteIcon /></Button>
              </Paper>
            </Grid>
          )
        })}

      </Grid>
    );
  };



  //TO DO : Customize and Style this Drag and Drop to Upload box as you want🧑‍💻😊
  return (
    <div style={{ margin: 20, minWidth: "300px", maxWidth: "400px", textAlign: "center" }}>

      <Grid
        item
        xs={12}
        onDragOver={handleImageDragOver}
        onDrop={handleImageDrop}
        style={{ margin: 20, marginBottom: 80 }}
      >



        {isImageUploading ? (
          <>

            <CircularProgress />
          </>
        ) : (
          <> <Typography
            style={{
              color: 'black',
              fontSize: '14px',
              fontWeight: '500',
            }}
          >
            Seleccione un archivo o arrastre y suelte aquí
          </Typography>
            <Typography>
              JPG, PNG o PDF, tamaño de archivo no superior a 10 MB
            </Typography>


            <input
              multiple
              accept=".ppt,.pptx,.pdf,video/*,.doc,.docx, .png, .jpg, .jpeg"
              // style={{ display: 'none', backgroundColor: 'red' }}
              id="upload-image"
              type="file"
              onChange={handleFileChange}
            /></>
        )}


      </Grid>

      {!isImageUploading && (
        <FileGrid files={uploadedFiles} />

      )}

    </div>
  );
};
export default FileUpload;