import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useTranslation } from "react-i18next";
import { Grid, TextField, Typography } from '@mui/material';
import { apiinvitedUser } from "../../apis/Services/EventService"
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlideNewAttende(props) {
    const { visible, setopenInvited, event, setReload } = props;
    const [t] = useTranslation("global");
    const [open, setOpen] = React.useState(visible);
    const [name, setName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");


    React.useEffect(() => {
        setOpen(visible)
    }, [visible])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setopenInvited(false)
    };


    const handleInvited = async () => {

        const body = {
            name: name,
            email: email
        }
        const apiSend = await apiinvitedUser(event, body)
        setName('')
        setEmail('')
        setOpen(false);
        setopenInvited(false)
        setReload(true)
    }
    return (
        <React.Fragment>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t("page.users.invite")}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} mt={2}>



                        <Grid item xs={12} sm={12}>
                            <label>{t("component.tableUsers.name")}</label>
                            <TextField
                                name="name"
                                variant="outlined"
                                value={name}
                                fullWidth
                                type='text'
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <label>{t("component.tableUsers.email")}</label>
                            <TextField
                                name="email"
                                variant="outlined"
                                value={email}
                                fullWidth
                                type='email'
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>



                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t("page.users.cancel")}</Button>
                    <Button onClick={handleInvited}>{t("page.users.send")}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}