import { BASE_URL } from '../config';
import { callApi, callApiFromData, callApiInit, callApiLogin } from '../utils';
import { getToken, getPublicToken } from './token';

// ++++++++ ORGANIZATION ++++++++ //

// SIGNOUT
export const getOrganizationList = async () => {
  const url = `${BASE_URL}api/v1/organization/all`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const getOrganizationUserList = async (organizationId) => {
  const url = `${BASE_URL}api/v1/organization/user/${organizationId}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const addOrganizationUserList = async (organizationId, body) => {
  const url = `${BASE_URL}api/v1/organization/user/${organizationId}`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const delOrganizationUser = async (organizationId, body) => {
  const url = `${BASE_URL}api/v1/organization/user/delete/${organizationId}`;
  const json = await callApi(url, 'POST', getToken(), body);
  return json;
};

export const getOrganization = async (id) => {
  const url = `${BASE_URL}api/v1/organization/${id}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const createOrganization = async (requestData) => {
  const url = `${BASE_URL}api/v1/organization`;
  const json = await callApi(url, 'POST', getToken(), requestData);

  return json;
};
export const editOrganization = async (requestData, id) => {
  const url = `${BASE_URL}api/v1/organization/${id}`;
  const json = await callApi(url, 'PUT', getToken(), requestData);

  return json;
};

export const deleteOrganization = async (id) => {
  const url = `${BASE_URL}api/v1/organization/${id}`;
  const json = await callApi(url, 'DELETE', getToken(), null);
  return json;
};

export const apiUploadImagen = async (image) => {
  const url = `${BASE_URL}api/v1/organization/uploadImagen`;
  const json = await callApiFromData(url, 'POST', getToken(), image);
  return json;
};

export const apiUploadFile = async (image, idEvent) => {
  const url = `${BASE_URL}api/v1/organization/uploadFile/${idEvent}`;
  const json = await callApiFromData(url, 'POST', getToken(), image);
  return json;
};
