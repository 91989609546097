import React from "react";

const validateEmail = (input) => {
  input = input.replace(" ", "");
  var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (validRegex.test(input)) {
    //console.log("Valid email address!");
    return true;
  } else {
    //console.log("Invalid email address!");
    return false;
  }
};

const modalMsgFunction = (isOpen, type, title = "", msg = "") => {
  let modalConfig = {
    success: false,
    error: false,
    loading: false,
    open: isOpen,
    msg: "",
    title: "",
  };
  switch (type) {
    case 1:
      modalConfig.success = true;
      modalConfig.title = "OK";
      break;
    case 2:
      modalConfig.error = true;
      modalConfig.title = "Error";
      break;
    case 3:
      modalConfig.title = "Info";
      break;
    default:
      modalConfig.loading = true;
      break;
  }
  modalConfig.title = title ? title : modalConfig.title;
  modalConfig.msg = msg ? msg : modalConfig.msg;
  return modalConfig;
};

export { validateEmail, modalMsgFunction };
