import * as React from "react";
import {
  FormControl,
  MenuItem,
  Menu,
  Toolbar,
  Grid,
  Select,
  IconButton,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";

export default function MenuHeaderHome({
  openMenuMobile,
  changeLanguaje,
  languaje,
  t,
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuConfig, setmenuConfig] = React.useState(null);

  const open = Boolean(anchorEl);
  const openConfig = Boolean(menuConfig);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleConfigClick = (event) => {

    setmenuConfig(event.currentTarget);
  };
  const handleConfigClose = () => {
    setmenuConfig(null);
  };

  const logOut = () => {
    localStorage.removeItem("aet");
    return navigate("/login");
  };

  const optionConfigMenu = (
    <Grid className="containerMenuConfig">
      <FormControl size="small">
        <Select
          value={languaje}
          className="paddingSelect"
          style={{
            minWidth: "130px",
            textAlign: "left",
          }}
          onChange={(e) => changeLanguaje(e.target.value)}
        >
          <MenuItem value={"en"}>English</MenuItem>
          <MenuItem value={"es"}>Español</MenuItem>
          <MenuItem value={"pt"}>Português</MenuItem>
          <MenuItem value={"it"}>Italiano</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );

  return (
    <Toolbar style={{ backgroundColor: "#fff" }}>
      <IconButton
        color="primary"
        aria-label="open drawer"
        edge="start"
        onClick={openMenuMobile}
        sx={{ mr: 2, display: { sm: "none" } }}
      >
        <MenuIcon />
      </IconButton>
      {/* header */}

      <Grid
        item
        textAlign={"right"}
        width={"100%"}
        alignItems={"center"}
        display={"flex"}
        justifyContent={"flex-end"}
      >
        <div className="d-none-menuConfig">{optionConfigMenu}</div>
        <Menu
          anchorEl={menuConfig}
          open={openConfig}
          onClose={handleConfigClose}
        >
          {optionConfigMenu}
        </Menu>
        <IconButton
          onClick={handleConfigClick}
          size="small"
          sx={{ ml: 2 }}
          className="d-none-menuConfigIcon"
        >
          <SettingsIcon color="primary" sx={{ width: 32, height: 32 }} />
        </IconButton>

        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <AccountCircleIcon sx={{ width: 32, height: 32 }} />
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {/* <MenuItem onClick={handleClose}>
            {t("component.profileMenu.profile")}
          </MenuItem>
          <MenuItem onClick={handleClose}>
            {t("component.profileMenu.myAccount")}
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              handleClose();
              logOut();
            }}
          >
            {t("component.profileMenu.logout")}
          </MenuItem>
        </Menu>
      </Grid>
    </Toolbar>
  );
}
