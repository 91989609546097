import { BASE_URL } from "../config";
import { callApi } from "../utils";
import { getToken } from "./token";

// ++++++++ TAG ++++++++ //

//Get all Tag
export const getTagsList = async () => {
  const url = `${BASE_URL}api/v1/tag/all`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

//Get one TAG
export const getTag = async (id) => {
  const url = `${BASE_URL}api/v1/tag/${id}`;
  const json = await callApi(url, "GET", getToken(), null);
  return json;
};

//Add tag service
export const addTagService = async (jsonData) => {
  const url = `${BASE_URL}api/v1/tag`;
  const json = await callApi(url, "POST", getToken(), jsonData);
  return json;
};

//Update tag service
export const updateTagService = async (jsonData, id) => {
  const url = `${BASE_URL}api/v1/tag/${id}`;
  const json = await callApi(url, "PUT", getToken(), jsonData);
  return json;
};

//delete tag
export const deleteTagService = async (id) => {
  const url = `${BASE_URL}api/v1/tag/${id}`;
  const json = await callApi(url, "DELETE", getToken(), null);
  return json;
};
