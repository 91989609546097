import axios from 'axios';
import FormData from 'form-data';
import fs from 'fs';

import {
  createDecipheriv,
  createCipheriv,
  randomBytes,
} from 'crypto-browserify';
import { getToken } from './Services/token';

export const callApi = async (url, method, token, body) => {
  const localToken = validateToken(localStorage.getItem('session'));
  if (process.env.REACT_APP_AAA === 'true') {
    body = { data: encryptedaes256cbc(body, localToken) };
  }

  let options = {
    method: method,
    url: url,
    headers: {
      Authorization: token ? 'Bearer ' + token : '',
      'Content-Type': 'application/json',
    },
  };

  if (method !== 'DELETE') {
    Object.assign(options, { data: JSON.stringify(body) });
  }

  try {
    const responsecall = await axios(options);
    const json = responsecall.data;
    if (process.env.REACT_APP_AAA === 'true') {
      return await descryptedaes256cbc(json.data, localToken);
    }
    return json;
  } catch (error) {
    // window.location.replace("/login");
    // console.log('EERO', error.response.status);
  }
};
export const callApiInit = async (url, method, token, body) => {
  const responsecall = await axios({
    method: method,
    url: url,
    headers: {
      Authorization: token ? 'Bearer ' + token : '',
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(body),
  });

  const json = responsecall.data;

  return json;
};

export const callApiLogin = async (url, method, token, body) => {
  const localToken = validateToken(localStorage.getItem('session'));

  let bodyNew = {
    username: encryptedaes256cbcTextPlain(body.username, localToken),
    password: encryptedaes256cbcTextPlain(body.password, localToken),
  };

  try {
    const responsecall = await axios({
      method: method,
      url: url,
      headers: {
        Authorization: token ? 'Bearer ' + token : '',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(bodyNew),
    });

    const json = responsecall.data;

    if (process.env.REACT_APP_AAA === 'true') {
      return await descryptedaes256cbc(json.data, localToken);
    }

    return json;
  } catch (error) {
    return error;
  }
};

export const callApiFromData = async (url, method, token, image) => {
  const localToken = validateToken(localStorage.getItem('session'));
  const formData = new FormData();
  formData.append('image', image); // Agrega la imagen al FormData

  let options = {
    method: method,
    maxBodyLength: Infinity,
    url: url,
    headers: {
      Authorization: token ? 'Bearer ' + token : '',
    },
    data: formData,
  };

  const responsecall = await axios(options);

  const json = responsecall.data;

  return json;
};
export const authenticate = (data, next) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem('aet', data.access_token);
    localStorage.setItem('meta', data.meta);

    next();
  }
};

export const isAuthenticated = () => {
  if (typeof window == 'undefined') {
    return false;
  }

  if (localStorage.getItem('aet')) {
    return true;
  } else {
    return false;
  }
};

export function localEncrypted(value, key) {
  const token = validateToken(localStorage.getItem('session'));
  let encrypValue = encryptedaes256cbc(value, token);

  localStorage.setItem(key, encrypValue);
}
export function localDEncrypted(value) {
  const token = validateToken(localStorage.getItem('session'));
  let descrypValue = descryptedaes256cbc(value, token);
  return descrypValue;
}

export function descryptedaes256cbc(encryptedText, privateKey) {
  try {
    const encryptedArray = encryptedText.split(':');
    const iv = Buffer.from(encryptedArray[0], 'hex');
    const encryptedData = Buffer.from(encryptedArray[1], 'hex');
    const decipher = createDecipheriv(
      'aes-256-ctr',
      Buffer.from(privateKey),
      iv
    );
    let decrypted = decipher.update(encryptedData);
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    const decryptedText = decrypted.toString();
    return JSON.parse(decryptedText);
  } catch (error) {}
}

function encryptedaes256cbc(data, privateKey) {
  try {
    const iv = randomBytes(16);

    const cipher = createCipheriv('aes-256-ctr', Buffer.from(privateKey), iv);
    let encrypted = cipher.update(JSON.stringify(data));
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    const encryptedText = `${iv.toString('hex')}:${encrypted.toString('hex')}`;

    return encryptedText;
  } catch (error) {}
}

function encryptedaes256cbcTextPlain(data, privateKey) {
  try {
    const iv = randomBytes(16);

    const cipher = createCipheriv('aes-256-ctr', Buffer.from(privateKey), iv);
    let encrypted = cipher.update(data);
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    const encryptedText = `${iv.toString('hex')}:${encrypted.toString('hex')}`;

    return encryptedText;
  } catch (error) {}
}

export function finddataMeta() {
  try {
    const meta = localStorage.getItem('meta');
    const localToken = validateToken(localStorage.getItem('session'));
    return descryptedaes256cbc(meta, localToken);
  } catch (error) {
    // console.log(error)
  }
}

function validateToken(token) {
  //DES

  if (token) {
    let b = 0;
    let des = '';
    try {
      let dat = token;
      while (b < dat.length - 20) {
        des = des + dat.charAt(b);
        b = b + 21;
      }
      return des;
    } catch (error) {}
  }
}

// Truncate string
export const truncateString = (str, num) => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }

  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + '...';
};
