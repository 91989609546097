import * as React from "react";
import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
  OutlinedInput,
  Chip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import CustomInputText from "./CustomInputText";
import AlertError from "./AlertError";
import moment from "moment";
import {
  ApiGetSessionid,
  ApiSessionSave,
  ApiSessionSaveUpdate,
} from "../apis/Services/Session";
import { getEvent } from "../apis/Services/EventService";
import ModalCustom from "./ModalCustom";
import { modalMsgFunction } from "../assets/library/GlobalFunction";
import axios from "axios";
import imgDefault from "./../assets/img/Preview.png";
import { apiUploadImagen } from "../apis/Services/OrganizationService";
moment.utc();

export default function AddSession(props) {
  const {
    setOpenFormSession,
    saveNewSession,
    data,
    idEvent,
    tagsOptions,
    setReload,
    OpenFormSessionId,
    OpenFromEdit,
    Speakers,
    setModalConfig,
  } = props;
  const [t] = useTranslation("global");
  const [initEndPeriod, setInitEndPeriod] = useState([
    { initTime: "", initZone: "pm", endTime: "", endZone: "pm", dateTime: "" },
  ]);
  const [fullDescription, setFullDescription] = useState();
  const [shortDescription, setShortDescription] = useState("");
  const [SpeakerId, setSpeakerId] = useState(null);
  const [title, setTitle] = useState("");
  const [dateSession, setDateSession] = useState("");
  const [sessionType, setSessionType] = React.useState("default");
  const [streamer, setStreamer] = React.useState("default");
  const [streamerLink, setStreamerLink] = React.useState("");
  const [streamerPassword, setStreamerPassword] = React.useState("");
  const [edit, setEdit] = useState(false);

  const ApiGoogle = "AIzaSyBFF6Qh8uf7wKfjnZmj0zCkmNzRhOXD5rM";
  const [thumbnailUrl, setThumbnailUrl] = useState("");

  const [error, setError] = React.useState({
    open: false,
    title: false,
    date: false,
    type: false,
  });

  const [tags, setTags] = React.useState([]);

  const changeTags = (event) => {
    const {
      target: { value },
    } = event;
    setTags(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  function onChangeFullDescription(e) {
    setFullDescription(e);
  }

  const periodChangeValue = (index, event) => {
    let data = [...initEndPeriod];
    data[index][event.target.name] = event.target.value;
    setInitEndPeriod(data);
  };

  async function readURL(e) {
    const file = e.target.files[0];
    const uploadImagen = await apiUploadImagen(file);
    const url =
      "https://eventosappstorage.blob.core.windows.net/eventos/" +
      uploadImagen.name;
    setThumbnailUrl(url);
  }

  function convertToSessionTime(inputArray) {
    const sessionTimeArray = inputArray.map((timeObj) => {
      const { initTime, initZone, endTime, endZone, dateTime } = timeObj;

      // Concatenar la hora y la zona horaria

      let currentOffset = moment().utcOffset();

      const startTimeString = `${dateTime} ${initTime}`;
      const endTimeString = `${dateTime} ${endTime}`;

      // Crear objetos Moment.js para las horas de inicio y fin
      const startTime = moment(startTimeString, "YYYY-MM-DD hh:mm A").utcOffset(
        currentOffset
      );
      const endTimes = moment(endTimeString, "YYYY-MM-DD  hh:mm A").utcOffset(
        currentOffset
      );

      return {
        time_start: startTime.toISOString(),
        time_end: endTimes.toISOString(),
      };
    });

    return { sessionTime: sessionTimeArray };
  }

  function convertToTagsArray(inputArray) {
    const tagsArray = inputArray.map((tagName, index) => {
      return { id: tagName };
    });

    return { tag: tagsArray };
  }

  const addTimeSection = () => {
    let newfield = {
      initTime: "",
      initZone: "pm",
      endTime: "",
      endZone: "pm",
      dateTime: "",
    };

    setInitEndPeriod([...initEndPeriod, newfield]);
  };

  const removeTimeSection = (index) => {
    let data = [...initEndPeriod];
    data.splice(index, 1);
    setInitEndPeriod(data);
  };

  const sendData = async () => {
    let _error = { title: false, date: false, type: false };
    if (!title) {
      _error.title = true;
    }

    if (sessionType === "default") {
      _error.type = true;
    }

    if (_error.title || _error.date || _error.type) {
      _error.open = true;
      setError(_error);
      return;
    } else {
      setError(_error);
    }

    const tagsapi = convertToTagsArray(tags);
    const times = convertToSessionTime(initEndPeriod);

    let json = {
      title: title,
      type: sessionType,
      descriptionShort: shortDescription,
      descriptionFull: fullDescription,
      provider: streamer,
      providerID: streamer,
      providerPassword: streamerPassword ? streamerPassword : "123456",
      providerUrl: streamerLink,
      status: 0,
      tag: tagsapi.tag,
      sessionTime: times.sessionTime,
      thumbnail: thumbnailUrl,
      speaker: SpeakerId,
    };

    if (OpenFormSessionId > 0) {
      const update = ApiSessionSaveUpdate(json, OpenFormSessionId);
      if (update) {
        setModalConfig(modalMsgFunction(true, 1, "OK", "Event Update"));
      } else {
        setModalConfig(
          modalMsgFunction(true, 2, "Error", "Error update Session")
        );
      }

      setOpenFormSession(false);
      setReload(true);
    } else {
      const save = ApiSessionSave(json, idEvent);
      if (save) {
        setModalConfig(modalMsgFunction(true, 1, "OK", "Event Update"));
      } else {
        setModalConfig(
          modalMsgFunction(true, 2, "Error", "Error update Session")
        );
      }

      setOpenFormSession(false);
      setReload(true);
    }
  };

  React.useEffect(() => {
    getDataID(OpenFormSessionId);
  }, [OpenFormSessionId]);

  function getImagenVideo(link) {
    if (streamer === "Youtube" && link) {
      const videoCodeRegex = /v=([A-Za-z0-9_-]+)/;
      const match = link.match(videoCodeRegex);
      const videoCode = match && match[1];

      axios
        .get(
          `https://www.googleapis.com/youtube/v3/videos?key=${ApiGoogle}&id=${videoCode}&part=snippet`
        )
        .then((response) => {
          const thumbnailUrl =
            response.data.items[0].snippet.thumbnails.maxres.url;

          setThumbnailUrl(thumbnailUrl);
        })
        .catch((error) => {
          console.error("Error al obtener la miniatura:", error);
        });
    }

    if (streamer === "Vimeo" && link) {
      axios
        .head(link)
        .then((response) => {
          const thumbnailUrl = response.headers["thumbnail-url"]; // Esta es una convención, pero podría variar
          if (thumbnailUrl) {
            setThumbnailUrl(thumbnailUrl);

          } else {

          }
        })
        .catch((error) => {
          console.error("Error al obtener la miniatura:", error);
        });
    }
  }
  async function getDataID(id) {
    if (parseInt(OpenFormSessionId) > 0) {
      const eventDetail = await ApiGetSessionid(OpenFormSessionId);
      // console.log(eventDetail)
      if (eventDetail) {
        setTitle(eventDetail.title);
        setSessionType(eventDetail.type);
        setStreamer(eventDetail.provider);
        setStreamerPassword(eventDetail.streamerPassword);
        setStreamerLink(eventDetail.providerUrl);
        setShortDescription(eventDetail.descriptionShort);
        setFullDescription(eventDetail.descriptionFull);
        setThumbnailUrl(eventDetail.thumbnail);
        setSpeakerId(eventDetail.speaker);
        let tagsList = [];
        eventDetail.tag.map((value) => {
          tagsList.push(value.tag.id);
        });

        //timesession
        let timesession = [];

        eventDetail.time.map((value) => {
          timesession.push({
            initTime: moment(value.time_start).format("HH:mm"),
            initZone: "pm",
            endTime: moment(value.time_end).format("HH:mm"),
            endZone: "pm",
            dateTime: moment(value.time_start).format("YYYY-MM-DD"),
          });
        });

        setTags(tagsList);
        setInitEndPeriod(timesession);
      }
    }
  }
  return (
    <>
      <Box
        component={"div"}
        padding={2}
        minWidth={"50vw"}
        maxWidth={"100%"}
        className="widthContextAdd"
      >
        <form>
          <Typography>{t("component.addSession.title")}</Typography>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            required
            value={title}
            error={error.title}
            onChange={(e) => setTitle(e.target.value)}
            disabled={!OpenFromEdit}
          />
          <Typography marginTop={1}>
            {t("component.addSession.sessionType")}
          </Typography>
          <FormControl size="small" fullWidth required>
            <Select
              size="small"
              value={sessionType}
              onChange={(e) => setSessionType(e.target.value)}
              name="initZone"
              error={error.type}
              disabled={!OpenFromEdit}
            >
              <MenuItem value={"default"} disabled>
                Select option
              </MenuItem>

              {data.type.map((item) => {
                return <MenuItem value={item.id}>{item.name}</MenuItem>;
              })}
            </Select>
          </FormControl>

          {initEndPeriod.map((input, index) => {
            return (
              <Grid container mt={2} spacing={1} key={index}>
                <Grid item xs={5} md={3}>
                  <Typography> {t("component.addSession.date")}</Typography>
                </Grid>

                <Grid item xs={5} md={3}>
                  <Typography>
                    {t("component.addSession.startTime")}
                    {moment().utcOffset() / 60}
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ display: { xs: "none", md: "block" } }}>
                  <Typography>
                    {t("component.addSession.endTime")}
                    {moment().utcOffset() / 60}
                  </Typography>
                </Grid>

                <Grid item xs={3} textAlign={"right"}>
                  {index == 0 && (
                    <Button
                      variant="outlined"
                      endIcon={<AddIcon />}
                      onClick={addTimeSection}
                      disabled={!OpenFromEdit}
                    >
                      {t("component.addSession.addButton")}
                    </Button>
                  )}
                </Grid>

                <Grid item xs={12} md={3} display={"inline-flex"}>
                  <TextField
                    variant="outlined"
                    type="date"
                    size="small"
                    name="dateTime"
                    // value={"2023-01-01"}
                    value={moment(input.dateTime).format("YYYY-MM-DD")}
                    onChange={(event) => periodChangeValue(index, event)}
                    fullWidth
                    disabled={!OpenFromEdit}
                  />
                </Grid>

                <Grid item xs={12} md={3} display={"inline-flex"}>
                  <TextField
                    size="small"
                    value={input.initTime}
                    inputProps={{ style: { textAlign: "center" } }}
                    style={{
                      marginRight: "5px",
                      justifyContent: "center",
                    }}
                    type="time"
                    name="initTime"
                    onChange={(event) => periodChangeValue(index, event)}
                    fullWidth
                    disabled={!OpenFromEdit}
                  />
                </Grid>

                <Grid item xs={12} md={3} display={"inline-flex"}>
                  <TextField
                    size="small"
                    value={input.endTime}
                    inputProps={{ style: { textAlign: "center" } }}
                    name="endTime"
                    style={{ marginRight: "5px" }}
                    type="time"
                    onChange={(event) => periodChangeValue(index, event)}
                    fullWidth
                    disabled={!OpenFromEdit}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  md={2}
                  sx={{ textAlign: { sm: "right", md: "center" } }}
                >
                  {initEndPeriod.length > 1 && (
                    <IconButton
                      onClick={() => {
                        if (OpenFromEdit) {
                          removeTimeSection(index);
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            );
          })}

          <div
            style={{
              background: "#F9F9F9",
              padding: "20px",
              marginTop: "15px",
              borderRadius: "10px",
            }}
          >
            <Typography> {t("component.addSession.stream")}</Typography>
            <FormControl size="small" fullWidth required>
              <Select
                size="small"
                value={streamer}
                onChange={(e) => setStreamer(e.target.value)}
                name="streamer"
                style={{ background: "#fff" }}
                disabled={!OpenFromEdit}
              >
                <MenuItem value={"default"} disabled>
                  Select option
                </MenuItem>
                <MenuItem value={"Zoom"}>Zoom</MenuItem>
                <MenuItem value={"Youtube"}>YouTube</MenuItem>
                <MenuItem value={"Vimeo"}>Vimeo</MenuItem>
              </Select>
            </FormControl>

            {streamer === "Zoom" ? (
              <>
                <Typography marginTop={"10px"} fontWeight={"bold"}>
                  Zoom Metting ID
                </Typography>

                <Typography marginTop={"10px"}>Streamer identifier</Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Zoom Meeting ID"
                  value={streamerLink}
                  onChange={(e) => {
                    setStreamerLink(e.target.value);
                  }}
                  disabled={!OpenFromEdit}
                />

                <Typography marginTop={"10px"}>Meeting password</Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="password"
                  value={streamerPassword}
                  onChange={(e) => setStreamerPassword(e.target.value)}
                  disabled={!OpenFromEdit}
                />
              </>
            ) : streamer === "Youtube" ? (
              <>
                <Typography marginTop={"10px"} fontWeight={"bold"}>
                  YouTube Link
                </Typography>
                <Typography marginTop={"10px"}>Streamer identifier</Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="YouTube Link"
                  value={streamerLink}
                  onChange={(e) => {
                    setStreamerLink(e.target.value);
                    getImagenVideo(e.target.value);
                  }}
                  disabled={!OpenFromEdit}
                />
              </>
            ) : streamer === "Vimeo" ? (
              <>
                <Typography marginTop={"10px"} fontWeight={"bold"}>
                  Vimeo Link
                </Typography>
                <Typography marginTop={"10px"}>Streamer identifier</Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Vimeo Link"
                  value={streamerLink}
                  onChange={(e) => {
                    setStreamerLink(e.target.value);
                    getImagenVideo(e.target.value);
                  }}
                  disabled={!OpenFromEdit}
                />
              </>
            ) : (
              <></>
            )}

            {streamer != "default" ? (
              <>
                <Typography marginTop={1}>{"Thumbnail"}</Typography>
                <div className="image-upload">
                  <label htmlFor="file-input">
                    <img
                      id="imgPreview"
                      src={thumbnailUrl ? thumbnailUrl : imgDefault}
                      style={{ width: "200px" }}
                    />
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    onChange={(e) => {
                      if (OpenFromEdit) {
                        setEdit(true);
                        readURL(e);
                      }
                    }}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <Typography marginTop={1}>
            {t("component.addSession.speakers")}
          </Typography>
          <FormControl size="small" fullWidth required>
            <Select
              size="small"
              value={SpeakerId}
              onChange={(e) => setSpeakerId(e.target.value)}
              name="initZone"
              error={error.type}
              disabled={!OpenFromEdit}
            >
              <MenuItem value={"default"} disabled>
                Select option
              </MenuItem>

              {Speakers.map((item) => {
                return (
                  <MenuItem value={item.id}>
                    {item.firtname} {item.lastname}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <Typography marginTop={"10px"}>
            {t("component.addSession.shortDescription")}
          </Typography>
          <TextField
            size="small"
            rows={3}
            multiline
            fullWidth
            value={shortDescription}
            onChange={(e) => setShortDescription(e.target.value)}
            disabled={!OpenFromEdit}
          />

          <Typography marginTop={"10px"}>
            {t("component.addSession.fullDescription")}
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <div style={{ width: "100%" }}>
                <CustomInputText
                  value={fullDescription}
                  onChange={onChangeFullDescription}
                  disabled={!OpenFromEdit}
                />
              </div>
            </Grid>
          </Grid>

          <Typography marginTop={"10px"}>
            {t("component.addSession.tags")}
          </Typography>
          <FormControl fullWidth>
            <Select
              disabled={!OpenFromEdit}
              multiple
              value={tags}
              onChange={changeTags}
              input={<OutlinedInput />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => {
                    const da = tagsOptions.find((e) => e.id === value);
                    return <Chip key={da.value} label={da.name} />;
                  })}
                </Box>
              )}
            >
              {tagsOptions.map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}

                  //style={getStyles(item, item, item)}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div style={{ textAlign: "center", marginTop: "12px" }}>
            <Button
              color="secondary"
              style={{ minWidth: "120px", margin: "0 5px" }}
              variant="outlined"
              onClick={() => setOpenFormSession(false)}
            >
              {t("component.addSession.cancel")}
            </Button>
            <Button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                sendData();
              }}
              color="primary"
              style={{ minWidth: "120px", margin: "0 5px" }}
              variant="contained"
              disabled={!OpenFromEdit}
            >
              {t("component.addSession.save")}
            </Button>
          </div>
        </form>
        <AlertError error={error} t={t} setError={setError} />
      </Box>
    </>
  );
}
