import * as React from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import About from "./About";
import Agenda from "./Agenda";
import Sponsors from "./Sponsors";
import Speakers from "./Speakers";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import imgEa from "./../../assets/img/imgEarth.png";
import imgfami from "./../../assets/img/imgFamily.png";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ minHeight: "300px" }}
      {...other}
    >
      {value === index && <> {children}</>}
    </div>
  );
}

export default function Index() {
  const [tabActive, setTabActive] = React.useState(0); //9=about 1= agenda ....
  const handleChange = (event, newValue) => {
    setTabActive(newValue);
  };
  const data = {
    title: "Anual Session 2023",
    calendar: new Date(),
    hour: "6:00 PM - 9:00 PM (utc-0)",
  };

  return (
    <>
      <div
        className="contentImgPreviewHeader "
        style={{
          backgroundImage: `url(${imgEa})`,
        }}
      ></div>
      <Container style={{ marginTop: "100px", marginBottom: "20px" }}>
        <Card>
          <CardContent>
            <Grid container marginBottom={"30px"}>
              <Grid item xs={12} md={6}>
                <div style={{ padding: "20px", textAlign: "center" }}>
                  <img
                    src={imgfami}
                    alt="Image"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "300px",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize={"30px"}
                  fontWeight={"bold"}
                  marginBottom={"20px"}
                >
                  {data.title}
                </Typography>
                <Typography
                  fontSize={"19px"}
                  display={"flex"}
                  marginBottom={"20px"}
                >
                  <CalendarTodayIcon style={{ marginRight: "5px" }} />
                  {data.calendar.toDateString()}
                </Typography>
                <Typography
                  fontSize={"19px"}
                  display={"flex"}
                  marginBottom={"20px"}
                >
                  <QueryBuilderIcon style={{ marginRight: "5px" }} />
                  {data.hour}
                </Typography>
                <Button
                  size="large"
                  style={{ minWidth: "200px", textAlign: "center" }}
                  color="primary"
                  variant="contained"
                >
                  get tickets
                </Button>
                <Typography marginTop={"20px"}>
                  You're registered Add a guest
                </Typography>
              </Grid>
            </Grid>

            <div
              style={{
                background: "rgb(237, 237, 237)",
                background:
                  "linear-gradient(180deg, rgb(237, 237, 237) 0%, rgb(255, 255, 255) 49%, rgb(255, 255, 255) 100%)",
              }}
            >
              <Tabs
                value={tabActive}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                <Tab label="About" value={0} style={{ color: "#000" }} />
                <Tab label="Agenda" value={1} style={{ color: "#000" }} />
                <Tab label="Sponsors" value={2} style={{ color: "#000" }} />
                <Tab label="Speakers" value={3} style={{ color: "#000" }} />
              </Tabs>
            </div>

            <Divider />

            <CustomTabPanel value={tabActive} index={0}>
              <About />
            </CustomTabPanel>
            <CustomTabPanel value={tabActive} index={1}>
              <Agenda />
            </CustomTabPanel>
            <CustomTabPanel value={tabActive} index={2}>
              <Sponsors />
            </CustomTabPanel>
            <CustomTabPanel value={tabActive} index={3}>
              <Speakers />
            </CustomTabPanel>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
