import * as React from "react";
import Layout from "../../components/Layout";
import { Box, Button, Drawer, Grid, Modal, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TableVisited from "../../components/TableVisited";
import AddSponsorsView from "../../components/AddSponsorView";
import { localDEncrypted } from "../../apis/utils";
import {
    apideleteSponsors,
    getSponsorsList,
} from "../../apis/Services/Sponsors";
import { modalMsgFunction } from "../../assets/library/GlobalFunction";
import ModalCustom from "../../components/ModalCustom";
import { getVisitedAll } from "../../apis/Services/EventService";
import { ApiGetSessionList } from "../../apis/Services/Session";

import { Calendar, dayjsLocalizer } from 'react-big-calendar'
import dayjs from 'dayjs'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.css'



export default function Index() {
    const [t] = useTranslation("global");
    const [modalConfig, setModalConfig] = React.useState(modalMsgFunction(false));
    const [Reload, setReload] = React.useState(false);
    const [idEvent, setidEvent] = React.useState("");
    const [eventList, seteventList] = React.useState([]);
    const [calevents, setCalEvents] = React.useState([]);
    const localizer = dayjsLocalizer(dayjs)
    const [modalContent, setmodalContent] = React.useState([])


    React.useEffect(() => {
        getData();
    }, []);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const eventColors = (event) => {
        const colorClasses = ['event-color-1', 'event-color-2', 'event-color-3', 'event-color-4', 'event-color-5'];
        // Selecciona una clase de color al azar para el tipo "session"
        const randomClass = colorClasses[Math.floor(Math.random() * colorClasses.length)];
        return { className: randomClass };

    };



    const handleSelectedEvent = (event) => {
        console.log(event)
        setmodalContent(event)
        handleOpen()
        // setSelectedEmployee(
        //   myEmployees.items.filter((item) => item.id === event.resource.userId)[0]
        // );
        // setSelectedEvent(event);
        // $("#calendar-event-modal").modal("show");
    };


    async function getData() {
        let idEvent = localStorage.getItem("selectEvent");
        let id = localDEncrypted(idEvent);
        GetDataAll(id);
    }







    async function GetDataAll(event) {
        const sessionList = await ApiGetSessionList(event);
        seteventList(sessionList);
        console.log(sessionList)

        const eventItem = sessionList.flatMap((json, index) => {
            return json.time.map((item, idex) => {
                const dat = {
                    id: idex,
                    title: item.session.title,
                    allDay: false,
                    start: new Date(item.time_start),
                    end: new Date(item.time_end),
                    type: item.type,
                    desc: item.desc
                };

                return dat;
            });
        });

        setCalEvents(eventItem);


    }


    const defaultMessages = {
        date: 'Fecha',
        time: 'Tiempo',
        event: 'Evento',
        allDay: 'Todos los días',
        week: 'Semana',
        work_week: 'Semana Trabajo',
        day: 'Día',
        month: 'Mes',
        previous: 'Anterior',
        next: 'Siguiente',
        yesterday: 'Ayer',
        tomorrow: 'Mañana',
        today: 'Hoy',
        agenda: 'Agenda',
        noEventsInRange: 'There are no events in this range.',
        showMore: function showMore(total) {
            return '+' + total + ' más';
        },
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };



    return (
        <Layout>

            <Grid container>
                <Grid item xs={12} md={3}>
                    <h1> {t("component.menu.calendar")}</h1>
                </Grid>

                <Grid item xs={12} mt={6}>


                    <Calendar
                        selectable
                        resizable
                        showAllEvents={false}
                        events={calevents}
                        defaultView="month"
                        scrollToTime={new Date(1970, 1, 1, 6)}
                        defaultDate={new Date()}
                        localizer={localizer}
                        style={{ height: 'calc(100vh - 350px' }}
                        views={['month', 'week', 'day']}
                        eventPropGetter={(event) => eventColors(event)}
                        rtl={false}
                        culture="es"
                        messages={defaultMessages}
                        onSelectEvent={(event) => handleSelectedEvent(event)}

                    />


                </Grid>
            </Grid>


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {modalContent.title}
                    </Typography>
                    <Typography
                        id="modal-modal-description"
                        sx={{
                            mt: 2,
                            maxHeight: '300px', // Establece una altura máxima
                            overflow: 'auto'  // Habilita el desplazamiento
                        }}
                    >
                        {modalContent.desc}
                    </Typography>
                </Box>
            </Modal>


        </Layout>
    );
}
