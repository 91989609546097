import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Toolbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import LateralMenu from "./LateralMenu";
import MenuHeader from "./MenuHeader";
import MenuHeaderHome from "./MenuHeaderHome";
import LateralMenuHome from "./LateralMenuHome";

const drawerWidth = 260;

export default function Layout(props) {
  const { window, children, isHome, isAdmin } = props;
  const [t, i18n] = useTranslation("global");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const openMenuMobile = () => {
    setMobileOpen(!mobileOpen);
  };

  const [languaje, setLanguaje] = React.useState(i18n.language);

  const changeLanguaje = (valueLanguaje) => {
    i18n.changeLanguage(valueLanguaje);
    setLanguaje(valueLanguaje);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;




  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        {isHome ? (
          <MenuHeaderHome
            openMenuMobile={openMenuMobile}
            changeLanguaje={changeLanguaje}
            languaje={languaje}
            t={t}
          />
        ) : (
          <MenuHeader
            openMenuMobile={openMenuMobile}
            changeLanguaje={changeLanguaje}
            languaje={languaje}

            t={t}
          />
        )}
      </AppBar>
      {isHome ? (
        <LateralMenuHome
          drawerWidth={drawerWidth}
          container={container}
          mobileOpen={mobileOpen}
          openMenuMobile={openMenuMobile}
          isAdmin={isAdmin}
          t={t}
        />
      ) : (
        <LateralMenu
          drawerWidth={drawerWidth}
          container={container}
          mobileOpen={mobileOpen}
          openMenuMobile={openMenuMobile}

          t={t}
        />
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}
