import * as React from "react";
import {
  FormControl,
  MenuItem,
  Menu,
  Toolbar,
  Grid,
  Button,
  Select,
  IconButton,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import { finddataMeta, localDEncrypted, localEncrypted } from "../apis/utils";
import { getEventList } from "../apis/Services/EventService";
import NameInitialsAvatar from "./AvatarName"

export default function MenuHeader({
  openMenuMobile,
  changeLanguaje,
  languaje,
  t,
}) {
  const navigate = useNavigate();
  const [switchSelect, setSwitchSelect] = React.useState("default");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuConfig, setmenuConfig] = React.useState(null);
  const [listEvent, setlistEvent] = React.useState([])
  const open = Boolean(anchorEl);
  const openConfig = Boolean(menuConfig);
  const [username, setusername] = React.useState('')


  async function getMeta() {
    const permitionSuperAdmin = await finddataMeta();
    const json = JSON.parse(permitionSuperAdmin)
    setusername(json.name)
  }



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleConfigClick = (event) => {

    setmenuConfig(event.currentTarget);
  };
  const handleConfigClose = () => {
    setmenuConfig(null);
  };

  const logOut = () => {
    localStorage.removeItem("aet");
    localStorage.removeItem("org");
    localStorage.removeItem("selectEvent");
    return navigate("/login");
  };

  const goToPreview = () => {

    let idEvent = localStorage.getItem("selectEvent");
    let idEventItem = localDEncrypted(idEvent);
    // console.log(process.env.REACT_APP_BASELANDING)

    window.open(process.env.REACT_APP_BASELANDING + "detail/" + btoa(idEventItem), "_blank")
  };

  const changeEvent = (eventName) => {
    if (eventName == "all") {
      return navigate("/myevents");
    } else {
      localEncrypted(eventName, "selectEvent");
      window.location.replace("/overviews");
      setSwitchSelect(eventName);
    }
  };

  const listAllEvent = async () => {
    let IdOrg = localStorage.getItem("org");
    let id = localDEncrypted(IdOrg);
    const eventList = await getEventList(id)
    // console.log(eventList)
    setlistEvent(eventList)


    let idEvent = localStorage.getItem("selectEvent");
    let idEventItem = localDEncrypted(idEvent);


    setSwitchSelect(idEventItem)
  };

  React.useEffect(() => {
    listAllEvent()
    getMeta()
  }, [])

  const optionConfigMenu = (
    <Grid
      className="containerMenuConfig"

    // justifyContent="center"
    // alignItems="center"
    >
      <FormControl>
        <Select
          size="small"
          className="paddingSelect"
          value={switchSelect}
          onChange={(e) => changeEvent(e.target.value)}
          style={{
            minWidth: "185px",
            textAlign: "left",
          }}
        >

          {/* 
          <MenuItem value={"default"} selected>
            {t("component.menu.switch")}
          </MenuItem> */}


          {listEvent.map((item) => {
            const truncatedName = item.name.slice(0, 20) + "..";
            return <MenuItem value={item.id} key={item.id} >
              {truncatedName}
            </MenuItem>
          })}






          <MenuItem value={"all"}>{t("component.menu.goBackOrganization")}</MenuItem>
        </Select>
      </FormControl>
      {/* <Button
        size="small"
        color="primary"
        variant="contained"
        style={{
          minWidth: "140px",
        }}
      >
        {t("component.button.publish")}
      </Button> */}
      <Button
        size="small"
        color="secondary"
        variant="outlined"
        style={{
          minWidth: "140px",
        }}
        onClick={() => goToPreview()}
      >
        {t("component.button.preview")}
      </Button>
      <FormControl size="small">
        <Select
          value={languaje}
          className="paddingSelect"
          style={{
            minWidth: "130px",
            textAlign: "left",
          }}
          onChange={(e) => changeLanguaje(e.target.value)}
        >
          <MenuItem value={"en"}>English</MenuItem>
          <MenuItem value={"es"}>Español</MenuItem>
          <MenuItem value={"pt"}>Português</MenuItem>
          <MenuItem value={"it"}>Italiano</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );

  return (
    <Toolbar style={{ backgroundColor: "#fff" }}>
      <IconButton
        color="primary"
        aria-label="open drawer"
        edge="start"
        onClick={openMenuMobile}
        sx={{ mr: 2, display: { sm: "none" } }}
      >
        <MenuIcon />
      </IconButton>
      {/* header */}

      <Grid
        item
        textAlign={"right"}
        width={"100%"}
        alignItems={"center"}
        display={"flex"}
        justifyContent={"flex-end"}
      >
        <div className="d-none-menuConfig">{optionConfigMenu}</div>
        <Menu
          anchorEl={menuConfig}
          open={openConfig}
          onClose={handleConfigClose}
        >
          {optionConfigMenu}
        </Menu>
        <IconButton
          onClick={handleConfigClick}
          size="small"
          sx={{ ml: 2 }}
          className="d-none-menuConfigIcon"
        >
          <SettingsIcon color="primary" sx={{ width: 32, height: 32 }} />
        </IconButton>

        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {username && (<NameInitialsAvatar name={username} />)}
          {/* <AccountCircleIcon sx={{ width: 32, height: 32 }} /> */}
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {/* <MenuItem onClick={handleClose}>
            {t("component.profileMenu.profile")}
          </MenuItem> */}
          {/* <MenuItem onClick={handleClose}>
            {t("component.profileMenu.myAccount")}
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              handleClose();
              logOut();
            }}
          >
            {t("component.profileMenu.logout")}
          </MenuItem>
        </Menu>
      </Grid>
    </Toolbar>
  );
}
