import * as React from "react";
import Layout from "../../components/Layout";
import { Button, Drawer, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TableVisited from "../../components/TableVisited";
import AddSponsorsView from "../../components/AddSponsorView";
import { localDEncrypted } from "../../apis/utils";
import {
  apideleteSponsors,
  getSponsorsList,
} from "../../apis/Services/Sponsors";
import { modalMsgFunction } from "../../assets/library/GlobalFunction";
import ModalCustom from "../../components/ModalCustom";
import { getVisitedAll } from "../../apis/Services/EventService";
import AlertDialogSlideNewAttende from "./invited";
export default function Index() {
  const [t] = useTranslation("global");
  const [modalConfig, setModalConfig] = React.useState(modalMsgFunction(false));
  const [OpenFromEdit, setOpenFromEdit] = React.useState(false);
  const [openFormSponsors, setOpenFormSponsors] = React.useState(false);
  const [Reload, setReload] = React.useState(false);
  const [idEvent, setidEvent] = React.useState("");
  const [OpenFormSponsorsId, setOpenFormSponsorsId] = React.useState("");
  const [openInvited, setopenInvited] = React.useState(false)
  const [sponsortData, setsponsortData] = React.useState([]);
  const rows = [];



  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    if (Reload) {
      setReload(false);
      GetDataAll(idEvent);
    }
  }, [Reload]);

  async function getData() {
    let idEvent = localStorage.getItem("selectEvent");
    let id = localDEncrypted(idEvent);
    GetDataAll(id);
  }

  async function GetDataAll(event) {
    const data = await getVisitedAll(event);
    setsponsortData(data);
    setidEvent(event);
  }

  const saveNewSponsor = (newSponsor) => {
    setOpenFormSponsors(false);
  };

  const closeModal = () => {
    setModalConfig({ ...modalConfig, open: false });
  };

  return (
    <Layout>
      <ModalCustom modalConfig={modalConfig} setOpen={closeModal} />
      <Grid container>
        <Grid item xs={12} md={3}>
          <h1> {t("component.menu.visited")}</h1>
        </Grid>



        <Grid item xs={12} mt={6}>

          <Grid>
            <Button size="medium"
              color="primary"
              variant="contained"
              onClick={() => { setopenInvited(true) }}
              style={{
                marginRight: "10px",
                marginBottom: "10px",
                minWidth: "140px",
                backgroundColor: "green",
                color: "white",
              }}> {t("page.users.invite")} </Button>
          </Grid>


          <TableVisited
            rows={sponsortData}
          />
        </Grid>
        <AlertDialogSlideNewAttende visible={openInvited} setopenInvited={setopenInvited} event={idEvent} setReload={setReload} />
      </Grid>


    </Layout>
  );
}
