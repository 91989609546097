import { BASE_URL } from '../config';
import { callApi, callApiInit, callApiLogin } from '../utils';
import { getToken, getPublicToken } from './token';


export const ApiGetSessionList = async (idEvent) => {
  const url = `${BASE_URL}api/v1/session/all/${idEvent}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};



export const ApiGetSpeakerList = async (idEvent) => {
  const url = `${BASE_URL}api/v1/speakers/all/${idEvent}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};





export const ApiGetSessionid = async (id) => {
  const url = `${BASE_URL}api/v1/session/${id}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};

export const ApiGetSpeakerid = async (id) => {
  const url = `${BASE_URL}api/v1/speakers/${id}`;
  const json = await callApi(url, 'GET', getToken(), null);
  return json;
};



export const ApiSessionDelete = async (idSession) => {
  const url = `${BASE_URL}api/v1/session/${idSession}`;
  const jsonResult = await callApi(url, 'DELETE', getToken(), null);
  return jsonResult;
};

export const ApiSpeakerDelete = async (idSpeaker) => {
  const url = `${BASE_URL}api/v1/speakers/${idSpeaker}`;
  const jsonResult = await callApi(url, 'DELETE', getToken(), null);
  return jsonResult;
};





export const ApiSessionSave = async (json, idEvent) => {
  const url = `${BASE_URL}api/v1/session/${idEvent}`;
  const jsonResult = await callApi(url, 'POST', getToken(), json);
  return jsonResult;
};

export const ApiSessionSaveUpdate = async (json, idSession) => {
  const url = `${BASE_URL}api/v1/session/${idSession}`;
  const jsonResult = await callApi(url, 'PUT', getToken(), json);
  return jsonResult;
};




export const ApiSpeakerSave = async (json, idEvent) => {
  const url = `${BASE_URL}api/v1/speakers/${idEvent}`;
  const jsonResult = await callApi(url, 'POST', getToken(), json);
  return jsonResult;
};

export const ApiSpekaerSaveUpdate = async (json, idSpeaker) => {
  const url = `${BASE_URL}api/v1/speakers/${idSpeaker}`;
  const jsonResult = await callApi(url, 'PUT', getToken(), json);
  return jsonResult;
};
