import * as React from "react";
import {
  VisibilityOff,
  Visibility,
  Google,
  Facebook,
  Apple,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/advlogo.png";
import { useTranslation } from "react-i18next";

export default function Recover() {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const [showPassword, setShowPassword] = React.useState(false);
  const [user, setUser] = React.useState("");
  const [pass, setPass] = React.useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const login = () => {

    //send email recovery
    
    return navigate("/login");
  };

  return (
    <>
      <Grid
        container
        minHeight={"102vh"}
        spacing={2}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid item xs={12} md={6}>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <img
              src={logo}
              alt="logo"
              width="160"
              height="160"
              className="imgIcon"
            />
          </Grid>
          <Grid item xs={10} marginBottom={3}></Grid>
          <Grid item xs={10} md={8} margin={"auto"}>
            <div style={{ textAlign: "center" }}>
              <h1>{t("page.recovery.title")}</h1>
              <Typography mb={2}> {t("page.recovery.subtitle")}</Typography>
            </div>
            <form className="">
              <label className="text-left">{t("page.recovery.email")}</label>
              <TextField
                variant="outlined"
                name="email"
                placeholder={t("page.recovery.email")}
                fullWidth
                onChange={(e) => setUser(e.target.value)}
              />
              <div style={{ textAlign: "right" }}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "20px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    login();
                  }}
                  type="submit"
                >
                  {t("page.recovery.send")}
                </Button>
              </div>
            </form>
          </Grid>
        </Grid>
        <Grid
          item
          xs={0}
          display={{ sm: "none", md: "block" }}
          md={6}
          style={{
            height: '100vh', // Establece la altura al 100% de la ventana
            backgroundImage: 'url("/static/media/login.a6d359c6.png")', // Reemplaza con la URL de tu imagen
            backgroundSize: 'cover', // Ajusta el tamaño de la imagen para que cubra completamente el fondo
            backgroundPosition: 'center', // Centra la imagen
            padding: 0,
          }}
        >

        </Grid>
      </Grid>
    </>
  );
}
