import * as React from "react";
import Layout from "../../components/Layout";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardContent,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddSession from "../../components/AddSession";
import AddSpeaker from "../../components/addSpeaker";
import { localDEncrypted, finddataMeta } from "../../apis/utils";
import { getEvent, getTagsList } from "../../apis/Services/EventService";
import {
  ApiGetSessionList,
  ApiGetSpeakerList,
  ApiSessionDelete,
  ApiSpeakerDelete,
} from "../../apis/Services/Session";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { modalMsgFunction } from "../../assets/library/GlobalFunction";
import LoadingView from "../../components/Loading";
import ModalCustom from "../../components/ModalCustom";


import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { useDropzone } from 'react-dropzone';
import FileUpload from '../../components/fileUpload'
import NameInitialsAvatar from "../../components/AvatarName";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <> {children}</>}
    </div>
  );
}

export default function Index() {
  const [modalConfig, setModalConfig] = React.useState(modalMsgFunction(false));
  const [loading, setloading] = React.useState(true);
  const [t] = useTranslation("global");
  const [tabActive, setTabActive] = React.useState(0); // 0=agenda 1= speaker
  const [agenda, setAgenda] = React.useState([]);
  const [speaker, setSpeaker] = React.useState([]);
  const [openFormSession, setOpenFormSession] = React.useState(false);
  const [OpenFormSessionId, setOpenFormSessionId] = React.useState("");
  const [OpenFromEdit, setOpenFromEdit] = React.useState(false);
  const [openFormSpeaker, setOpenFormSpeaker] = React.useState(false);
  const [OpenFormSpeakerId, setOpenFormSpeakerId] = React.useState("");
  const [newDocument, setnewDocument] = React.useState('')
  const [viewModalnewDocument, setviewModalnewDocument] = React.useState(false)
  const [idEvent, setidEvent] = React.useState("");
  const [eventList, seteventList] = React.useState([]);
  const [Reload, setReload] = React.useState(false);
  const [data, setData] = React.useState({
    logo: "",
    name: "",
    start: "",
    end: "",
    description: "",
    status: "",
    type: [],
  });



  const [tagsOptions, settagsOptions] = React.useState([]);

  const closeModal = (type) => {
    setModalConfig({ ...modalConfig, open: false });
  };

  const handleChange = (event, newValue) => {
    setTabActive(newValue);
  };
  const addSesssion = () => {
    setOpenFormSession(true);
    setOpenFormSessionId(0);
    setOpenFromEdit(true);
  };

  const EditSesssion = (id) => {
    setOpenFormSession(true);
    setOpenFormSessionId(id);
    setOpenFromEdit(true);
  };

  const EditSpeaker = (id) => {
    setOpenFormSpeakerId(id);
    setOpenFromEdit(true);
    setOpenFormSpeaker(true);
  };

  const ViewSpeaker = (id) => {
    setOpenFormSpeakerId(id);
    setOpenFromEdit(false);
    setOpenFormSpeaker(true);
  };
  const ViewSesssion = (id) => {
    setOpenFormSession(true);
    setOpenFormSessionId(id);
    setOpenFromEdit(false);
  };

  const addSpeaker = () => {
    setOpenFormSpeakerId("");
    setOpenFromEdit(true);
    setOpenFormSpeaker(true);
  };
  const saveNewSpeaker = (newSpeaker) => {
    setOpenFormSpeaker(false);

    setSpeaker((content) => [...content, newSpeaker]);
  };
  const saveNewAgendaSession = (newSession) => {
    setOpenFormSession(false);
    setAgenda((content) => [...content, newSession]);
  };

  React.useEffect(() => {
    getData();
    getSpeaker();

  }, []);

  React.useEffect(() => {
    if (Reload) {
      setloading(true);
      GetDataAll(idEvent);
      GetAllSpeaker(idEvent);
    }
  }, [Reload]);

  async function GetAllSpeaker(event) {
    setloading(true);
    const listSpeaker = await ApiGetSpeakerList(event);

    setSpeaker(listSpeaker);
    setloading(false);
  }
  async function GetDataAll(event) {
    setloading(true);
    const eventDetail = await getEvent(event);
    setidEvent(event);
    setData({
      logo: eventDetail.logo,
      name: eventDetail.name,
      start: eventDetail.start,
      end: eventDetail.end,
      description: eventDetail.description,
      status: eventDetail.status,
      type: eventDetail.datatype,
    });

    const tags = await getTagsList();
    settagsOptions(tags);

    const sessionList = await ApiGetSessionList(event);
    seteventList(sessionList);
    setloading(false);
  }

  async function getSpeaker() {
    let idEvent = localStorage.getItem("selectEvent");
    let id = localDEncrypted(idEvent);
    GetAllSpeaker(id);
  }

  async function getData() {
    let idEvent = localStorage.getItem("selectEvent");
    let id = localDEncrypted(idEvent);
    GetDataAll(id);
  }

  async function deleteSpeaker(id) {
    setModalConfig(modalMsgFunction(true, 0, "Error", "Delete Speaker Error"));
    const deleteresp = await ApiSpeakerDelete(id);
    setModalConfig(modalMsgFunction(true, 1, "OK", "Event Delete"));
    GetAllSpeaker(idEvent);
  }
  async function deleteSession(id) {
    setModalConfig(modalMsgFunction(true, 0, "Error", "Delete event Error"));

    const deleteresp = await ApiSessionDelete(id);

    setModalConfig(modalMsgFunction(true, 1, "OK", "Event Delete"));

    GetDataAll(idEvent);
  }



  return (
    <>
      <ModalCustom modalConfig={modalConfig} setOpen={closeModal} />
      <Layout>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Tabs
              value={tabActive}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label={t("page.agenda.tabAgenda")}
                value={0}
                style={{ color: "#000" }}
              />
              <Tab
                label={t("page.agenda.tabSpeaker")}
                value={1}
                style={{ color: "#000" }}
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} md={8} textAlign={"right"}>
            <Button
              variant="contained"
              color="primary"
              style={{
                display: tabActive === 1 ? "initial" : "none",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              onClick={addSpeaker}
            >
              {t("page.agenda.addSpeakerButton")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                display: tabActive === 0 ? "initial" : "none",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              onClick={addSesssion}
            >
              {t("page.agenda.addSessionButton")}
            </Button>
          </Grid>
        </Grid>
        <Card sx={{ width: { xs: "90vw", sm: "100%" } }}>
          <CardContent>
            <CustomTabPanel value={tabActive} index={0}>
              <Typography fontWeight="bold">
                {eventList.length} {t("page.agenda.titleAgenda")}
              </Typography>

              {loading ? (
                <LoadingView />
              ) : (
                <>
                  {eventList.length === 0 ? (
                    <>
                      <Typography
                        fontWeight="bold"
                        textAlign={"center"}
                        marginTop={6}
                      >
                        {t("page.agenda.noSession")}
                      </Typography>
                      <Typography fontWeight="bold" textAlign={"center"}>
                        {t("page.agenda.addSessionText")}
                      </Typography>
                    </>
                  ) : (
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell align="left">Description</TableCell>
                            <TableCell align="left">Provider</TableCell>

                            <TableCell align="center">Option</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {eventList?.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="left">{row.title}</TableCell>
                              <TableCell align="left">
                                {row.descriptionShort}
                              </TableCell>
                              <TableCell align="left">{row.provider}</TableCell>
                              <TableCell align="center">
                                <IconButton
                                  onClick={() => {
                                    ViewSesssion(row.id);
                                  }}
                                >
                                  <VisibilityIcon />
                                </IconButton>

                                <IconButton
                                  color="danger"
                                  onClick={() => {
                                    setnewDocument(row.id);
                                    setviewModalnewDocument(true)
                                  }}
                                >
                                  <CreateNewFolderIcon />
                                </IconButton>


                                <IconButton
                                  color="warning"
                                  onClick={() => {
                                    EditSesssion(row.id);
                                  }}
                                >
                                  <ModeEditIcon />
                                </IconButton>
                                <IconButton
                                  color="error"
                                  onClick={() => {
                                    deleteSession(row.id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>





                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </>
              )}
            </CustomTabPanel>
            <CustomTabPanel value={tabActive} index={1}>
              <Typography fontWeight="bold">
                {speaker.length} {t("page.agenda.titleSpeaker")}
              </Typography>

              {loading ? (
                <LoadingView />
              ) : (
                <>
                  {speaker.length === 0 ? (
                    <>
                      <Typography
                        fontWeight="bold"
                        textAlign={"center"}
                        marginTop={6}
                      >
                        {t("page.agenda.noSpeaker")}
                      </Typography>
                      <Typography fontWeight="bold" textAlign={"center"}>
                        {t("page.agenda.addSpeakerText")}
                      </Typography>
                    </>
                  ) : (
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Photo</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Company</TableCell>

                            <TableCell align="left">Title</TableCell>
                            <TableCell align="left">Pass</TableCell>
                            <TableCell align="center">Option</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {speaker.map((row, index) => {

                            const passSession = btoa(idEvent + "-" + row.id);

                            return (<TableRow key={index}>
                              <TableCell align="center" width={"60px"}>
                                {row.photo ? (
                                  <img
                                    style={{
                                      borderRadius: "25px",
                                      maxWidth: "50px",
                                      maxHeight: "50px",
                                    }}
                                    src={row.photo}
                                    alt="image"
                                  />
                                ) : (<>
                                  {row.firtname && (<NameInitialsAvatar name={row.firtname + row.lastname} />)}
                                </>)}
                              </TableCell>
                              <TableCell align="left">
                                {row.firtname + " " + row.lastname}
                              </TableCell>
                              <TableCell align="left">{row.company}</TableCell>
                              <TableCell align="left">{row.title}</TableCell>
                              <TableCell align="left">{passSession}</TableCell>
                              <TableCell align="center">
                                <IconButton
                                  onClick={() => {
                                    ViewSpeaker(row.id);
                                  }}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                                <IconButton
                                  color="warning"
                                  onClick={() => {
                                    EditSpeaker(row.id);
                                  }}
                                >
                                  <ModeEditIcon />
                                </IconButton>
                                <IconButton
                                  color="error"
                                  onClick={() => {
                                    deleteSpeaker(row.id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>)
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </>
              )}
            </CustomTabPanel>
          </CardContent>
        </Card>
        {/* Session Form */}
        <Drawer
          anchor={"right"}
          open={openFormSession}
          onClose={() => setOpenFormSession(false)}
        >
          <AddSession
            saveNewSession={saveNewAgendaSession}
            setOpenFormSession={setOpenFormSession}
            data={data}
            setModalConfig={setModalConfig}
            idEvent={idEvent}
            tagsOptions={tagsOptions}
            setReload={setReload}
            OpenFormSessionId={OpenFormSessionId}
            OpenFromEdit={OpenFromEdit}
            Speakers={speaker}
          />
        </Drawer>
        {/* Speaker form */}
        <Drawer
          anchor={"right"}
          open={openFormSpeaker}
          onClose={() => setOpenFormSpeaker(false)}
        >
          <AddSpeaker
            saveNewSpeaker={saveNewSpeaker}
            setOpenFormSpeaker={setOpenFormSpeaker}
            data={data}
            idEvent={idEvent}
            setReload={setReload}
            OpenFormSpeakerId={OpenFormSpeakerId}
            OpenFromEdit={OpenFromEdit}
            setModalConfig={setModalConfig}
          />
        </Drawer>

        <Drawer
          anchor={"right"}
          open={viewModalnewDocument}
          onClose={() => setviewModalnewDocument(false)}
        >

          <FileUpload idEventSession={newDocument} />



        </Drawer>


      </Layout>
    </>
  );
}
