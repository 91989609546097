import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "../../components/Layout";
import {
  addTagService,
  deleteTagService,
  getTag,
  getTagsList,
  updateTagService,
} from "../../apis/Services/TagService";
import TableTag from "../../components/TableTag";
import { useTranslation } from "react-i18next";
import { modalMsgFunction } from "../../assets/library/GlobalFunction";
import ModalCustom from "../../components/ModalCustom";
import { finddataMeta } from "../../apis/utils";

export default function Index() {
  const [t] = useTranslation("global");
  const [modalConfig, setModalConfig] = React.useState(modalMsgFunction(false));
  const [superAdmin, setsuperAdmin] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [isEditModal, setIsEditModal] = React.useState(false);
  const [idTagSelected, setIdTagSelected] = React.useState(0);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [error, setError] = React.useState({ name: false, description: false });
  const [tagList, setTagList] = React.useState([]);

  React.useEffect(() => {
    getListTag();
    getMeta();
  }, []);

  async function getMeta() {
    const permitionSuperAdmin = await finddataMeta();
    const json = JSON.parse(permitionSuperAdmin);

    setsuperAdmin(json.superadmin);
  }

  const getListTag = () => {
    getTagsList()
      .then((result) => {
        console.log(result);
        setTagList(result);
      })
      .catch((err) => console.log("err", err));
  };
  const openModalFunction = (isEdit, id) => {
    if (isEdit) {
      setModalConfig(modalMsgFunction(true, 0));
      setIsEditModal(true);
      setIdTagSelected(id);
      getTag(id)
        .then((result) => {
          setModalConfig(modalMsgFunction(false));
          setName(result.name);
          setDescription(result.description);
          setOpenModal(true);
        })
        .catch((err) => setModalConfig(modalMsgFunction(true, 2)));
    } else {
      setName("");
      setDescription("");
      setIsEditModal(false);
      setOpenModal(true);
    }
  };

  const createTag = () => {
    let _error = { name: false, description: false };
    if (!name) {
      _error.name = true;
    }
    if (!description) {
      _error.description = true;
    }

    setError(_error);
    if (_error.name || _error.description) {
      return;
    }
    let json = { name, description };
    setOpenModal(false);
    setModalConfig(modalMsgFunction(true, 0));
    addTagService(json)
      .then((result) => {
        if (result) {
          setModalConfig(modalMsgFunction(true, 1));
          getListTag();
        } else {
          setModalConfig(modalMsgFunction(true, 2));
          getListTag();
        }
      })
      .catch((err) => {
        setModalConfig(modalMsgFunction(true, 2));
        getListTag();
      });
  };

  const editTag = () => {
    let _error = { name: false, description: false };
    if (!name) {
      _error.name = true;
    }
    if (!description) {
      _error.description = true;
    }

    setError(_error);
    if (_error.name || _error.description) {
      return;
    }
    let json = { name, description };
    setOpenModal(false);
    setModalConfig(modalMsgFunction(true, 0));
    updateTagService(json, idTagSelected)
      .then((result) => {
        if (result) {
          setModalConfig(modalMsgFunction(true, 1));
          getListTag();
        } else {
          setModalConfig(modalMsgFunction(true, 2));
          getListTag();
        }
      })
      .catch((err) => {
        setModalConfig(modalMsgFunction(true, 2));
        getListTag();
      });
  };

  const deleteFunction = (idTag) => {
    setIdTagSelected(idTag);
    setOpenConfirm(true);
  };

  const deleteTagFunction = () => {
    setOpenConfirm(false);
    setModalConfig(modalMsgFunction(true, 0));

    deleteTagService(idTagSelected)
      .then((result) => {
        console.log(result);
        if (result.status == "OK") {
          getListTag();
        }
        setModalConfig(modalMsgFunction(true, 1));
      })
      .catch((err) => setModalConfig(modalMsgFunction(true, 2)));
  };

  const closeModal = () => {
    setModalConfig({ ...modalConfig, open: false });
  };

  return (
    <>
      <Layout isHome={true} isAdmin={superAdmin}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h1> {t("page.tag.title")}</h1>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          mt={2}
          mb={2}
          style={{
            alignContent: "flex-end",
            alignItems: "flex-end",
            textAlign: "right",
          }}
        >
          <Button
            variant="contained"
            fullWidth
            size="large"
            onClick={() => openModalFunction(false, null)}
            style={{ width: "100%" }}
          >
            {t("page.tag.create")}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <TableTag
            rows={tagList}
            editFunction={openModalFunction}
            deleteFunction={deleteFunction}
          />
        </Grid>
      </Layout>

      <Dialog
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle textAlign={"center"}>
          {isEditModal ? t("page.tag.editModal") : t("page.tag.addModal")}
        </DialogTitle>
        <DialogContent>
          <Typography color={"#000"} mt={2}>
            {t("page.tag.name")}
          </Typography>
          <TextField
            size="small"
            placeholder=""
            fullWidth
            name="name"
            error={error.name}
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Typography color={"#000"} mt={2}>
            {t("page.tag.description")}
          </Typography>
          <TextField
            size="small"
            placeholder=""
            fullWidth
            name="description"
            error={error.description}
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          {isEditModal ? (
            <Button variant="contained" onClick={() => editTag()} fullWidth>
              {t("page.tag.edit")}
            </Button>
          ) : (
            <Button variant="contained" onClick={() => createTag()} fullWidth>
              {t("page.tag.save")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle fontWeight={"bold"}>
          {t("page.tag.titleDelete")}
        </DialogTitle>
        <DialogContent>
          <Typography> {t("page.tag.subTitleDelete")}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setOpenConfirm(false)}
          >
            {t("page.tag.cancel")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => deleteTagFunction()}
          >
            {t("page.tag.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      <ModalCustom modalConfig={modalConfig} setOpen={closeModal} />
    </>
  );
}
